import { computed } from "mobx";
import { IVideoService } from "../../../../shared/api/BackendApi";
import { ThemeItem } from "../../../../shared/components/basicThemesSection/ThemeItem";
import { PreviousExamsStructure } from "../../../../shared/contentStructure/PreviousExamsStructure";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { CourseInfo } from "../../../../shared/stores/Product";
import { IProgressStore } from "../../../../shared/stores/ProgressStore";
import { FreeContentSectionDto } from "../../../../types/courses/dto/FreeContentSectionDto";
import { TrainingGroundInfoDto } from "../../../../types/courses/dto/TrainingGroundInfoDto";
import { TranslationsDto } from "../../../../types/translations/dto/TranslationsDto";

export interface FinishedThemesSection {
  __type: "FinishedThemesSection";
  themeSectionsModel: Pick<ThemeSectionsModel, "finishedThemes">;
}

export interface InProgressThemesSection {
  __type: "InProgressThemesSection";
  themeSectionsModel: Pick<ThemeSectionsModel, "inProgressThemes">;
}

export interface NotStartedThemesSection {
  __type: "NotStartedThemesSection";
  themeSectionsModel: Pick<ThemeSectionsModel, "notStartedThemes">;
}

export interface ThemesWithFreeStepsSectionProperties {
  __type: "ThemesWithFreeStepsSection";
  themeSectionsModel: Pick<ThemeSectionsModel, "themesWithFreeSteps">;
  activeCourseParams: ActiveCourseParams;
  section: FreeContentSectionDto;
}

export interface TrainingGroundSection {
  __type: "TrainingGroundSection";
  activeCourseParams: ActiveCourseParams;
  trainingGroundInfos: TrainingGroundInfoDto[];
}

export interface PreviousExamsSection {
  __type: "PreviousExamsSection";
  model: PreviousExamsSectionModel;
}

export interface OtherCoursesSection {
  __type: "OtherCoursesSection";
  courses: CourseInfo[];
  sectionTitle: keyof TranslationsDto;
  onCourseClick: (slug: string) => void;
  productTitle: string;
}

export type DashboardSection =
  | FinishedThemesSection
  | InProgressThemesSection
  | NotStartedThemesSection
  | TrainingGroundSection
  | PreviousExamsSection
  | ThemesWithFreeStepsSectionProperties
  | OtherCoursesSection;

export class ThemeSectionsModel {
  @computed get finishedThemes() {
    return this.themes.filter(t => t.status === "finished");
  }

  @computed get inProgressThemes() {
    return this.themes.filter(t => t.status === "inProgress");
  }

  @computed get notStartedThemes() {
    return this.themes.filter(t => t.status === "notStarted" || t.status === "underDevelopment");
  }

  @computed get themesWithFreeSteps() {
    return this.themes.filter(t => t.themeStructure.hasStepsWithAccess);
  }

  constructor(private themes: ThemeItem[]) {}
}

export class PreviousExamsSectionModel {
  @computed private get courseProgress() {
    return this.progressStore.courseProgress;
  }

  @computed get startedExamThemes() {
    const themesProgress = this.courseProgress.previousExamsThemesProgress.filter(
      themeProgress => themeProgress.isStarted
    );

    return themesProgress.map(themeProgress => {
      const examTheme = this.previousExamsStructure.examThemes.find(theme => theme.id === themeProgress.id);

      if (examTheme === undefined) {
        throw new Error("Exam theme with progress should be stored in PreviousExamsStructure");
      }

      return {
        ...examTheme,
        theme: new ThemeItem(examTheme.theme, this.progressStore, this.videoService),
      };
    });
  }

  @computed get previousExamsSection() {
    return this.previousExamsStructure.previousExamsSection;
  }

  constructor(
    private previousExamsStructure: PreviousExamsStructure,
    public urlParams: ActiveCourseParams,
    private progressStore: IProgressStore,
    private videoService: IVideoService
  ) {}
}
