import qs from "qs";
import { ProductArticleListQueryParams } from "../../pages/articles/addProductArticleListRouting";
import { AuthorsForProductQueryParams } from "../../pages/authorsForProduct/addAuthorsForProductRouting";
import { CourseParams } from "../../pages/course/addCourseRouting";
import { ErrorParams } from "../../pages/error/addErrorsRoutes";
import { ProductParams } from "../../pages/product/addProductRouting";
import { appRoutes } from "../../routes";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";

export interface ContentReference {
  id: string;
  slug: string;
}

export type ActiveCourseParams = ProductParams & CourseParams;

export interface IUrl {
  toProductNestPage: () => string;
  toProductPage: (params: ProductParams) => string;
  toDashboardPage: (params: ActiveCourseParams) => string;
  toLogoutPage: () => string;
  toLessonPage: (params: ActiveCourseParams, stepRef: ContentReference) => string;
  toThemePage: (params: ActiveCourseParams, themeSlug: string) => string;
  toThemesPage: (params: ActiveCourseParams) => string;
  toThemeTestPage: (params: ActiveCourseParams, themeSlug: string, testSlug: string) => string;
  toArticlePage: (articleRef: ContentReference) => string;
  toProductArticleListPage: (params: ProductParams, queryParams?: ProductArticleListQueryParams) => string;
  toProductArticlePage: (params: ProductParams, articleRef: ContentReference) => string;
  toCourseArticlePage: (params: ActiveCourseParams, articleRef: ContentReference) => string;
  toBookmarksPage: (params: ActiveCourseParams) => string;
  toTibetLoginPage: (returnUrl?: string) => string;
  toTibetPurchaseLogin: (returnUrl: string) => string;
  toLoginPage: () => string;
  toCoursePage: (params: ActiveCourseParams) => string;
  toSearchPage: (params: ActiveCourseParams, phrase?: string) => string;
  toTrainingGroundPage: (params: ActiveCourseParams, trainingGroundSlug: string, initialThemeSlug?: string) => string;
  toPreviousExamsPage: (params: ActiveCourseParams) => string;
  toErrorPage: (params: ErrorParams) => string;
  toAuthorsForProductPage: (params: ProductParams, queryParams?: AuthorsForProductQueryParams) => string;
  toMyProfile: () => string;
  toTermsAndConditionsPage: () => string;
  toAboutUsPage: () => string;
  toMyProfileBPN: () => string;
  toBuyRedirectPage: () => string;
  toPurchaseConfirmationPage: () => string;
}

const createUrl = (): IUrl => {
  const toDashboardPage = (params: ActiveCourseParams) => {
    return appRoutes.dashboardRouting.getPath(params);
  };

  const toBuyRedirectPage = () => {
    return RouteConfiguration.BuyRedirect;
  };

  const toPurchaseConfirmationPage = () => {
    return RouteConfiguration.PurchaseConfirmation;
  };

  const toProductNestPage = () => {
    return RouteConfiguration.ProductNest;
  };

  const toProductPage = (params: ProductParams) => {
    return appRoutes.productRouting.getPath(params);
  };

  const toLogoutPage = () => {
    return RouteConfiguration.Logout;
  };

  const toLessonPage = (params: ActiveCourseParams, stepRef: ContentReference) => {
    return appRoutes.lessonsRoutes.getPath({ ...params, stepSlug: stepRef.slug });
  };

  const toThemePage = (params: ActiveCourseParams, themeSlug: string) => {
    return appRoutes.themesRoutes.getPath({ ...params, themeSlug });
  };

  const toThemesPage = (params: ActiveCourseParams) => {
    return appRoutes.themeListRoute.getPath(params);
  };

  const toThemeTestPage = (params: ActiveCourseParams, themeSlug: string, testSlug: string) => {
    return appRoutes.testsRoutes.getPath({ ...params, themeSlug, testSlug });
  };

  const toArticlePage = (articleRef: ContentReference) => {
    return appRoutes.articlesRoutes.getPath({ articleSlug: articleRef.slug });
  };

  const toProductArticleListPage = (params: ProductParams, queryParams?: ProductArticleListQueryParams) => {
    return appRoutes.productArticleListRouting.getPath(params, queryParams);
  };

  const toProductArticlePage = (params: ProductParams, articleRef: ContentReference) => {
    return appRoutes.productArticleRouting.getPath({ ...params, articleSlug: articleRef.slug });
  };

  const toCourseArticlePage = (params: ActiveCourseParams, articleRef: ContentReference) => {
    return appRoutes.courseArticleRouting.getPath({ ...params, articleSlug: articleRef.slug });
  };

  const toBookmarksPage = (params: ActiveCourseParams) => {
    return appRoutes.bookmarkRoutes.getPath(params);
  };

  const toTibetLoginPage = () => {
    const params = qs.stringify({ return_Url: window.location.pathname }, { addQueryPrefix: true });
    return `${RouteConfiguration.Login}${params}`;
  };

  const toTibetPurchaseLogin = (returnUrl: string) => {
    const params = qs.stringify({ return_Url: returnUrl }, { addQueryPrefix: true });
    return `${RouteConfiguration.Login}${params}`;
  };

  const toLoginPage = () => {
    const params = qs.stringify({ returnUrl: window.location.pathname }, { addQueryPrefix: true });
    return `${RouteConfiguration.LoginPage}${params}`;
  };

  const toSearchPage = (params: ActiveCourseParams, phrase?: string) => {
    const baseUrl = appRoutes.siteSearchRouting.getPath(params);

    let queryString = "";
    if (phrase) {
      queryString = qs.stringify({ phrase }, { addQueryPrefix: true });
    }

    return `${baseUrl}${queryString}`;
  };

  const toCoursePage = (params: ActiveCourseParams) => {
    return `/student/${params.productSlug}/${params.courseSlug}${RouteConfiguration.Dashboard}`;
  };

  const toTrainingGroundPage = (params: ActiveCourseParams, trainingGroundSlug: string, initialThemeSlug?: string) => {
    return appRoutes.trainingGroundsRouting.getPath({ ...params, trainingGroundSlug }, { initialThemeSlug });
  };

  const toPreviousExamsPage = (params: ActiveCourseParams) => {
    return appRoutes.previousExamsRouting.getPath(params);
  };

  const toErrorPage = (params: ErrorParams) => {
    return appRoutes.errorRouting.getPath(params);
  };

  const toAuthorsForProductPage = (params: ProductParams, queryParams?: AuthorsForProductQueryParams) => {
    return appRoutes.authorsForProduct.getPath(params, queryParams);
  };

  const toMyProfile = () => {
    return RouteConfiguration.TibetUserProfile;
  };

  const toMyProfileBPN = () => {
    return RouteConfiguration.TibetUserProfileBPN;
  };

  const toTermsAndConditionsPage = () => {
    return RouteConfiguration.TermsAndConditions;
  };

  const toAboutUsPage = () => {
    return RouteConfiguration.AboutUs;
  };

  return {
    toBuyRedirectPage,
    toPurchaseConfirmationPage,
    toProductNestPage,
    toProductPage,
    toDashboardPage,
    toLogoutPage,
    toLessonPage,
    toThemePage,
    toThemesPage,
    toThemeTestPage,
    toArticlePage,
    toProductArticleListPage,
    toProductArticlePage,
    toCourseArticlePage,
    toBookmarksPage,
    toTibetLoginPage,
    toTibetPurchaseLogin,
    toLoginPage,
    toSearchPage,
    toCoursePage,
    toTrainingGroundPage,
    toPreviousExamsPage,
    toErrorPage,
    toAuthorsForProductPage,
    toMyProfile,
    toTermsAndConditionsPage,
    toAboutUsPage,
    toMyProfileBPN,
  };
};

export const Url = createUrl();
