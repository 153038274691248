/* eslint-disable react/no-danger */
import KaTeX, { ParseError } from "katex";
import React, { useState, useMemo } from "react";
import "katex/dist/katex.min.css";

// https://github.com/sanity-io/latex-input/blob/master/src/components/LatexPreview.tsx
export type SchemaDefinition = {
  body: string;
};

type PreviewProps = {
  node: SchemaDefinition;
  layout: "inline" | "block";
};

type MathProps = {
  node: SchemaDefinition;
};

const LatexPreview = ({ node, layout }: PreviewProps) => {
  const latex = (node && node.body) || "";
  const isInline = layout === "inline";
  const [html, setHtml] = useState<string>("");
  const createHtml = () => {
    try {
      const str = KaTeX.renderToString(latex, {
        displayMode: !isInline,
        throwOnError: true,
      });
      setHtml(str);
    } catch (ex) {
      if (ex instanceof ParseError) {
        setHtml(`<span style="color:red">${ex.message}</span>`);
      }
    }
  };

  useMemo(createHtml, [latex, isInline]);
  if (isInline) {
    return <span className="Katex" dangerouslySetInnerHTML={{ __html: html }} />;
  }
  return <div className="Katex" dangerouslySetInnerHTML={{ __html: html }} />;
};

export const BlockMath = ({ node }: MathProps) => <LatexPreview node={node} layout="block" />;
export const InlineMath = ({ node }: MathProps) => <LatexPreview node={node} layout="inline" />;
