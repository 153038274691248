import { computed } from "mobx";
import { IUrl } from "../../../shared/models/Url";
import { SourceLink } from "../HeaderViewModel";
import { HeaderHamburgerViewModel } from "../headerHamburger/HeaderHamburgerViewModel";

export class HamburgerMenuLinks {
  @computed get primaryLinks(): SourceLink[] {
    const links: SourceLink[] = [];

    if (this.headerViewModel.currentContext === "productNest") {
      return links;
    }

    if (this.headerViewModel.currentContext === "product") {
      links.push(...this.headerViewModel.desktopHamburgerPrimaryLinks);
    }

    if (this.headerViewModel.currentContext === "course" && this.headerViewModel.baseHeaderLinks) {
      links.push(...this.headerViewModel.baseHeaderLinks);
    }

    return links;
  }

  @computed get secondaryLinks(): SourceLink[] {
    const links: SourceLink[] = [];

    if (this.headerViewModel.currentContext === "productNest") {
      return links;
    }

    if (this.headerViewModel.currentContext === "product") {
        links.push({
          source: "static",
          id: "aboutUs",
          href: this.url.toAboutUsPage(),
          translation: "aboutUs",
        });
    }

    if (this.headerViewModel.currentContext === "course") {
      links.push(...this.headerViewModel.desktopHamburgerPrimaryLinks);
    }

    return links;
  }

  @computed get studiesType() {
    if (!this.headerViewModel.activeProduct || !this.headerViewModel.activeProduct.studiesType) {
      return;
    }

    return this.headerViewModel.activeProduct.studiesType;
  }

  constructor(
    private headerViewModel: Pick<
      HeaderHamburgerViewModel,
      | "baseHeaderLinks"
      | "desktopHamburgerPrimaryLinks"
      | "currentContext"
      | "activeCourse"
      | "activeProduct"
      | "config"
    >,
    private url: IUrl
  ) {}
}
