import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { ContentView } from "react-mvvm";
import { ActivityIndicator } from "../../shared/components/activityIndicator/ActivityIndicator";
import { Banner } from "../../shared/components/banner/Banner";
import { SectionArticleListBlock } from "../../shared/components/blocks/SectionArticleListBlock/SectionArticleListBlock";
import { SectionIconListBlock } from "../../shared/components/blocks/SectionIconListBlock/SectionIconListBlock";
import { SectionImageBlock } from "../../shared/components/blocks/SectionImageBlock/SectionImageBlock";
import { SectionMultiQuoteBlock } from "../../shared/components/blocks/SectionMultiQuoteBlock/SectionMultiQuoteBlock";
import { SectionQuoteBlock } from "../../shared/components/blocks/SectionQuoteBlock/SectionQuoteBlock";
import { SectionTextBlock } from "../../shared/components/blocks/SectionTextBlock/SectionTextBlock";
import { CoursesSection } from "../../shared/components/coursesSection/CoursesSection";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import { SectionBlockTypeDto } from "../../types/shared/dto/SectionBlockTypeDto";
import { ProductViewModel } from "./ProductViewModel";
import { ProductHero } from "./productHero/ProductHero";

export const ProductView = observer(({ model }: { model: ProductViewModel }) => {
  const translations = useTranslations();

  if (!model.productSections) {
    return <article className="Product" />;
  }

  const {
    productSections,
    productHeroVM,
    product,
    courses,
    isLoading,
    goToCourse,
    coursesSectionId,
    handleLogin,
    userStore,
  } = model;
  const { title, productParams, seoTitle, seoDescription } = product;

  const [hasReadSykepleieBanner, setHasReadSykepleieBanner] = React.useState(() => {
    return localStorage.getItem("SykepleieBanner__read") === "true";
  });

  const [hasReadGluBanner, setHasReadGluBanner] = React.useState(() => {
    return localStorage.getItem("GluBanner__read") === "true";
  });

  const handleCloseBanner = (bannerValue: string) => {
    localStorage.setItem(bannerValue, "true");
    if (bannerValue === "SykepleieBanner__read") {
      setHasReadSykepleieBanner(true);
    } else if (bannerValue === "GluBanner__read") {
      setHasReadGluBanner(true);
    }
  };

  const handleBannerClick = async () => {
    await handleLogin();
  };

  return (
    <ContentView content={model.childPage}>
      <PageTitle title={seoTitle || title} description={seoDescription}>
        {!hasReadSykepleieBanner && productParams.productSlug === "sykepleie" && (
          <Banner
            isAuthenticated={userStore.isAuthenticated}
            buttonText="Logg inn med Feide"
            heading="Anatomi, fysiologi og biokjemi er gratis ut 2024!"
            text="Fremover vil Qudos vokse og bli en støtte til pensum for hele sykepleierutdanningen. For å feire dette åpner vi nå våre eksisterende kurs for alle sykepleiestudenter ut 2024. &#x1f973; Kom i gang i dag!"
            onClose={() => handleCloseBanner("SykepleieBanner__read")}
            variant="button"
            handleClick={handleBannerClick}
          />
        )}
        {!hasReadGluBanner && productParams.productSlug === "glu" && (
          <Banner
            isAuthenticated={userStore.isAuthenticated}
            buttonText="Qudos for helsepersonell"
            heading="Qudos til studenter!"
            text="Gyldendals digitale studenttjeneste vokser og endrer derfor navn fra Riktig til Qudos. Er du helsepersonell finner du kursene dine ved å klikke på knappen."
            onClose={() => handleCloseBanner("GluBanner__read")}
            variant="link"
          />
        )}
        <article className="Product">
          <ActivityIndicator className="Product__loader" isLoading={isLoading}>
            <div className="Product__hero">{productHeroVM && <ProductHero model={productHeroVM} />}</div>
            {courses && (
              <CoursesSection
                courses={courses}
                coursesSectionId={coursesSectionId}
                sectionTitle={"courses"}
                productName={title}
                onCourseClick={goToCourse}
              />
            )}
            {productSections && (
              <div className="Product__content">
                <ul className="Product__blocksList">
                  {productSections.map((block, i) => {
                    const nextBlockType =
                      i + 1 < productSections.length ? productSections[i + 1].sectionBlockType : null;
                    return (
                      <li
                        key={i}
                        className={classNames(
                          {
                            "Product__sectionItem--nextQuoteBlock":
                              nextBlockType === SectionBlockTypeDto.SectionQuoteBlock ||
                              nextBlockType === SectionBlockTypeDto.SectionMultipleQuotesBlock,
                          },
                          {
                            "Product__sectionItem--lastImageBlock":
                              nextBlockType &&
                              nextBlockType !== SectionBlockTypeDto.SectionImageBlock &&
                              block.sectionBlockType === SectionBlockTypeDto.SectionImageBlock,
                          },
                          {
                            "Product__sectionItem--textBlockWithImageBlockNext":
                              nextBlockType === SectionBlockTypeDto.SectionImageBlock &&
                              block.sectionBlockType === SectionBlockTypeDto.SectionTextBlock,
                          }
                        )}
                      >
                        {block.sectionBlockType === SectionBlockTypeDto.SectionTextBlock && block.sectionTextBlock && (
                          <SectionTextBlock key={i} {...block.sectionTextBlock} />
                        )}
                        {block.sectionBlockType === SectionBlockTypeDto.SectionIconListBlock &&
                          block.sectionIconListBlock && (
                            <SectionIconListBlock key={i} {...block.sectionIconListBlock} />
                          )}
                        {block.sectionBlockType === SectionBlockTypeDto.SectionImageBlock &&
                          block.sectionImageBlock && (
                            <SectionImageBlock key={i} {...block.sectionImageBlock} productParams={productParams} />
                          )}
                        {block.sectionBlockType === SectionBlockTypeDto.SectionQuoteBlock &&
                          block.sectionQuoteBlock && <SectionQuoteBlock key={i} {...block.sectionQuoteBlock} />}
                        {block.sectionBlockType === SectionBlockTypeDto.SectionMultipleQuotesBlock &&
                          block.sectionMultipleQuotesBlock && (
                            <SectionMultiQuoteBlock key={i} {...block.sectionMultipleQuotesBlock} />
                          )}
                        {block.sectionBlockType === SectionBlockTypeDto.SectionArticleListBlock &&
                          block.sectionArticleListBlock && (
                            <SectionArticleListBlock
                              key={i}
                              {...block.sectionArticleListBlock}
                              goBackText={translations.goToHomePage}
                              productParams={productParams}
                            />
                          )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </ActivityIndicator>
        </article>
      </PageTitle>
    </ContentView>
  );
});
