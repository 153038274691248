import { observer } from "mobx-react";
import React from "react";
import { useUrl } from "../../../../useRouter";
import { Level } from "../../title/Title";
import { HighlightedThumbnail } from "../highlightedThumbnail/HighlightedThumbnail";
import { useTestOverlayProps } from "../hooks/useTestOverlayProps";
import { TestThumbnailOverlay } from "../shared/TestThumbnailOverlay";
import { TestThumbnailInfo } from "../shared/testThumbnail/TestThumbnailInfo";
import { ThemeTestDomainThumbnailViewModel } from "./ThemeTestDomainThumbnailViewModel";

export type TestStateType = "default" | "passed" | "failed" | "locked" | "loading";

export type TestState =
  | { _type: "default" }
  | { _type: "passed"; correctAnswers: number }
  | { _type: "failed"; correctAnswers: number }
  | { _type: "locked" }
  | { _type: "loading" };

export interface ThemeTestDomainThumbnailProps {
  model: ThemeTestDomainThumbnailViewModel;
  thumbnailTitleLevel?: Level;
}

export const ThemeTestDomainThumbnail = observer((props: ThemeTestDomainThumbnailProps) => {
  const { model, thumbnailTitleLevel = 3 } = props;

  const {
    testStructure: { slug, title, subtitle, description, image, tasksCount, hasAccess, themeStructure },
    paymentSidePanelViewModel,
    testState,
  } = model;
  const url = useUrl();

  const overlayProps = useTestOverlayProps(hasAccess, paymentSidePanelViewModel, testState);

  const href = url.toThemeTestPage(themeStructure.courseStructure.urlParams, themeStructure.slug, slug);

  return (
    <HighlightedThumbnail
      title={{
        text: title,
        level: thumbnailTitleLevel,
      }}
      image={image}
      description={description}
      category={subtitle ? [subtitle] : [themeStructure.title]}
      backgroundColor={themeStructure.themeColor}
      href={href}
      type="Test"
      locked={!hasAccess}
      overlay={overlayProps && <TestThumbnailOverlay props={overlayProps} />}
      bottomContent={<TestThumbnailInfo testState={testState} tasksCount={tasksCount} href={href} />}
    />
  );
});
