/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError, AxiosInstance, CancelToken } from "axios";
import qs from "qs";
import { AboutUsDto } from "../../types/aboutus/dto/AboutUsDto";
import { GetAboutUsRequest } from "../../types/api/aboutus/GetAboutUsRequest";
import { GetArticleRequest } from "../../types/api/articles/GetArticleRequest";
import { GetAuthorRequest } from "../../types/api/authorsforproducts/GetAuthorRequest";
import { GetAuthorsForProductsRequest } from "../../types/api/authorsforproducts/GetAuthorsForProductsRequest";
import { CreateUserBookmarkRequest } from "../../types/api/bookmarks/CreateUserBookmarkRequest";
import { DeleteUserBookmarkRequest } from "../../types/api/bookmarks/DeleteUserBookmarkRequest";
import { GetUserBookmarksRequest } from "../../types/api/bookmarks/GetUserBookmarksRequest";
import { UpdateUserBookmarkRequest } from "../../types/api/bookmarks/UpdateUserBookmarkRequest";
import { GetConfigRequest } from "../../types/api/config/GetConfigRequest";
import { GetCoursePaymentRequest } from "../../types/api/courses/GetCoursePaymentRequest";
import { GetCourseStructureBySlugRequest } from "../../types/api/courses/GetCourseStructureBySlugRequest";
import { GetCourseStructureRequest } from "../../types/api/courses/GetCourseStructureRequest";
import { GetStepPreviewRequest } from "../../types/api/lessons/GetStepPreviewRequest";
import { GetStepRequest } from "../../types/api/lessons/GetStepRequest";
import { GetProductArticlesRequest } from "../../types/api/product/GetProductArticlesRequest";
import { GetProductsRequest } from "../../types/api/product/GetProductsRequest";
import { SubmitNewsletterSignUpRequest } from "../../types/api/product/SubmitNewsletterSignUpRequest";
import { GetProgressRequest } from "../../types/api/progress/GetProgressRequest";
import { UpdateStepCompletionStatusRequest } from "../../types/api/progress/UpdateStepCompletionStatusRequest";
import { UpdateVideoProgressRequest } from "../../types/api/progress/UpdateVideoProgressRequest";
import { SearchContentRequest } from "../../types/api/search/SearchContentRequest";
import { GetTestRequest } from "../../types/api/test/GetTestRequest";
import { GetTestResultRequest } from "../../types/api/test/GetTestResultRequest";
import { SubmitTestRequest } from "../../types/api/test/SubmitTestRequest";
import { GetTrainingGroundTaskPreviewRequest } from "../../types/api/trainingground/GetTrainingGroundTaskPreviewRequest";
import { GetTrainingGroundTasksRequest } from "../../types/api/trainingground/GetTrainingGroundTasksRequest";
import { GetTrainingGroundThemesRequest } from "../../types/api/trainingground/GetTrainingGroundThemesRequest";
import { GetUserRequest } from "../../types/api/user/GetUserRequest";
import { GetVideoDetailsRequest } from "../../types/api/video/GetVideoDetailsRequest";
import { ArticleDto } from "../../types/articles/dto/ArticleDto";
import { ArticleListItemDto } from "../../types/articles/dto/ArticleListItemDto";
import { AuthorDetailsDto } from "../../types/authorsforproducts/dto/AuthorDetailsDto";
import { UserBookmarkDto } from "../../types/bookmarks/dto/UserBookmarkDto";
import { ConfigDto } from "../../types/config/dto/ConfigDto";
import { CoursePaymentDto } from "../../types/courses/dto/CoursePaymentDto";
import { CourseStructureDto } from "../../types/courses/dto/CourseStructureDto";
import { LessonStepDto } from "../../types/dto/lessonstepdtos/LessonStepDto";
import { TaskDto } from "../../types/dto/tasks/TaskDto";
import { ProductDto } from "../../types/product/dto/ProductDto";
import { ProgressDto } from "../../types/progress/dto/ProgressDto";
import { SearchResultDto } from "../../types/search/dto/SearchResultDto";
import { AuthorDto } from "../../types/shared/dto/AuthorDto";
import { UserDto } from "../../types/shared/dto/UserDto";
import { TestDto } from "../../types/test/dto/TestDto";
import { TestResultDto } from "../../types/test/dto/TestResultDto";
import { TrainingGroundDto } from "../../types/trainingground/dto/TrainingGroundDto";
import { TrainingGroundThemeDto } from "../../types/trainingground/dto/TrainingGroundThemeDto";
import { VideoDetailsDto } from "../../types/video/dto/VideoDetailsDto";
import { IErrorService } from "../services/ErrorService";
import { HttpError } from "../services/errors/HttpError";
import { mapToStep, IStepService, mapToTask } from "./StepService";

export interface ICourseService {
  getCourseStructure: (
    req: GetCourseStructureRequest,
    cancelToken?: CancelToken
  ) => Promise<CourseStructureDto | undefined>;
  getCourseStructureBySlug: (req: GetCourseStructureBySlugRequest) => Promise<CourseStructureDto | undefined>;
  getCoursePayment: (req: GetCoursePaymentRequest) => Promise<CoursePaymentDto>;
}

export interface IUserService {
  getUser: (req: GetUserRequest) => Promise<UserDto | undefined>;
}

export interface IArticleService {
  getArticle: (req: GetArticleRequest) => Promise<ArticleDto>;
}

export interface IProgressService {
  getProgress: (req: GetProgressRequest) => Promise<ProgressDto>;
  updateVideoProgress: (req: UpdateVideoProgressRequest) => Promise<void>;
  updateStepCompletionStatusRequest: (req: UpdateStepCompletionStatusRequest) => Promise<void>;
}

export interface IVideoService {
  getVideoDetails: (req: GetVideoDetailsRequest) => Promise<VideoDetailsDto | undefined>;
}

export interface ITestService {
  getTest: (req: GetTestRequest) => Promise<TestDto>;
  submitTest: (req: SubmitTestRequest) => Promise<void>;
  getTestResult: (req: GetTestResultRequest) => Promise<TestResultDto | null>;
}

export interface IBookmarkService {
  createUserBookmark: (req: CreateUserBookmarkRequest) => Promise<void>;
  getUserBookmarks: (req: GetUserBookmarksRequest) => Promise<UserBookmarkDto[]>;
  deleteUserBookmark: (req: DeleteUserBookmarkRequest) => Promise<void>;
  updateUserBookmark: (req: UpdateUserBookmarkRequest) => Promise<void>;
}

export interface IConfigService {
  getConfig: (req: GetConfigRequest) => Promise<ConfigDto>;
}

export interface IProductService {
  getProductsInfo: (req: GetProductsRequest) => Promise<ProductDto[]>;
  submitNewsletterSignup: (req: SubmitNewsletterSignUpRequest) => Promise<void>;
}

export interface IProductArticleService {
  getProductArticles: (req: GetProductArticlesRequest) => Promise<ArticleListItemDto[]>;
}

export interface ISearchService {
  searchContent: (req: SearchContentRequest) => Promise<SearchResultDto>;
}

export interface ITrainingGroundService {
  getTrainingGroundThemes: (req: GetTrainingGroundThemesRequest) => Promise<TrainingGroundThemeDto[]>;
  getTrainingGroundTasks: (req: GetTrainingGroundTasksRequest) => Promise<TrainingGroundDto>;
}

export interface IAuthorsForProductService {
  getAuthorsForProduct: (req: GetAuthorsForProductsRequest) => Promise<AuthorDto[]>;
  getAuthor: (req: GetAuthorRequest) => Promise<AuthorDetailsDto>;
}

export class BackendApi
  implements
    ISearchService,
    IProductService,
    IProductArticleService,
    IConfigService,
    IBookmarkService,
    IArticleService,
    IVideoService,
    IProgressService,
    IUserService,
    ICourseService,
    IStepService,
    ITestService,
    ITrainingGroundService,
    IAuthorsForProductService
{
  constructor() {
    this.toastAxios = axios.create();
    this.toastAxios.interceptors.response.use(
      ok => ok,
      () => {
        this.ensureErrorServiceConfigured().showErrorToast();
        return Promise.resolve(undefined);
      }
    );
    this.splashAxios = axios.create();
    this.splashAxios.interceptors.response.use(
      ok => ok,
      (err: AxiosError) => {
        const response = err?.response;
        if (response && response.status !== 401) {
          this.ensureErrorServiceConfigured().showErrorSplash(new HttpError(response.status, response.statusText));
        }
        return Promise.reject(err);
      }
    );
  }

  private splashAxios: AxiosInstance;

  private toastAxios: AxiosInstance;

  private errorService?: IErrorService;

  public initErrorInterceptors(errorService: IErrorService) {
    this.errorService = errorService;
  }

  private ensureErrorServiceConfigured() {
    if (!this.errorService) {
      throw new Error("errorService in BackendApi was not initialized. Did you forget to call initErrorInterceptor?");
    }
    return this.errorService;
  }

  async getProductsInfo(_req: GetProductsRequest) {
    const response = await this.splashAxios.get<ProductDto[]>(`/student/api/getProducts`);
    return response.data;
  }

  async submitNewsletterSignup(req: SubmitNewsletterSignUpRequest) {
    await this.toastAxios.post<void>(`/student/api/submitNewsletterSignUp`, req);
  }

  async searchContent(req: SearchContentRequest) {
    const response = await this.splashAxios.get<SearchResultDto>(`/student/api/searchContent`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getTest(req: GetTestRequest) {
    const response = await this.splashAxios.get<TestDto>(`/student/api/getTest/${req.id}`, {
      params: { courseId: req.courseId },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getTestResult(req: GetTestResultRequest) {
    const response = await this.splashAxios.get<TestResultDto | null>(`/student/api/getTestResult`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async submitTest(req: SubmitTestRequest) {
    await this.splashAxios.post(`/student/api/submitTest`, req);
  }

  async getConfig(_req: GetConfigRequest) {
    const response = await this.splashAxios.get<ConfigDto>(`/student/api/getConfig`);
    return response.data;
  }

  async createUserBookmark(req: CreateUserBookmarkRequest) {
    await this.toastAxios.post<void>(`/student/api/createUserBookmark`, req);
  }

  async getUserBookmarks(req: GetUserBookmarksRequest) {
    const response = await this.splashAxios.get<UserBookmarkDto[]>(`/student/api/getUserBookmarks`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async deleteUserBookmark(req: DeleteUserBookmarkRequest) {
    await this.toastAxios.delete<DeleteUserBookmarkRequest>(`/student/api/deleteUserBookmark`, { data: req });
  }

  async updateUserBookmark(req: UpdateUserBookmarkRequest) {
    await this.toastAxios.post<void>(`/student/api/updateUserBookmark`, req);
  }

  async getArticle(req: GetArticleRequest) {
    const response = await this.splashAxios.get<ArticleDto>(`/student/api/getArticle/${req.slug}`);
    return response.data;
  }

  async getVideoDetails(req: GetVideoDetailsRequest) {
    const response = await this.toastAxios.get<VideoDetailsDto>(`/student/api/getVideoDetails/${req.stepId}`);
    return response?.data;
  }

  async getProgress(req: GetProgressRequest) {
    const response = await this.splashAxios.get<ProgressDto>(`/student/api/getProgress`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async updateVideoProgress(req: UpdateVideoProgressRequest) {
    await this.toastAxios.post<void>(`/student/api/updateVideoProgress`, req);
  }

  async updateStepCompletionStatusRequest(req: UpdateStepCompletionStatusRequest) {
    await this.toastAxios.post<void>(`/student/api/updateStepCompletionStatus`, req);
  }

  async getUser(_req: GetUserRequest) {
    const response = await this.splashAxios.get<UserDto | undefined>(`/student/api/getUser`);
    return response.status === 204 ? undefined : response.data;
  }

  async getCourseStructure(req: GetCourseStructureRequest, cancelToken?: CancelToken) {
    const response = await this.splashAxios
      .get<CourseStructureDto>(`/student/api/getCourseStructure/${req.id}`, {
        cancelToken,
      })
      .catch(e => {
        if (axios.isCancel(e)) {
          return;
        }

        throw new Error("Unhandled error in getCourseStructure");
      });

    return response?.data;
  }

  async getCoursePayment(req: GetCoursePaymentRequest) {
    const response = await this.splashAxios.get(`/student/api/getCoursePayment`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getStep(req: GetStepRequest) {
    const response = await this.splashAxios.get<LessonStepDto>(`/student/api/getStep`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return mapToStep(response.data);
  }

  async getStepPreview(req: GetStepPreviewRequest) {
    const response = await this.splashAxios.get<LessonStepDto>(`/student/api/GetStepPreview`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return mapToStep(response.data);
  }

  async getTrainingGroundTaskPreview(req: GetTrainingGroundTaskPreviewRequest) {
    const response = await this.splashAxios.get<TaskDto>(`/student/api/GetTrainingGroundTaskPreview`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return mapToTask(response.data);
  }

  async getTrainingGroundThemes(req: GetTrainingGroundThemesRequest) {
    const response = await this.splashAxios.get<TrainingGroundThemeDto[]>(`/student/api/getTrainingGroundThemes`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async getTrainingGroundTasks(req: GetTrainingGroundTasksRequest) {
    const response = await this.splashAxios.get<TrainingGroundDto>(`/student/api/getTrainingGroundTasks`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async getProductArticles(req: GetProductArticlesRequest) {
    const response = await this.splashAxios.get<ArticleListItemDto[]>(`/student/api/getProductArticles`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async getAuthorsForProduct(req: GetAuthorsForProductsRequest) {
    const response = await this.splashAxios.get<AuthorDto[]>(`/student/api/getAuthorsForProducts`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async getAuthor(req: GetAuthorRequest) {
    const response = await this.splashAxios.get<AuthorDetailsDto>(`/student/api/getAuthor`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async getAboutUs(req: GetAboutUsRequest) {
    const response = await this.splashAxios.get<AboutUsDto>(`/student/api/getAboutUs`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async getCourseStructureBySlug(req: GetCourseStructureBySlugRequest): Promise<CourseStructureDto | undefined> {
    return Promise.resolve(undefined);
  }
}
