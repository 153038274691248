import { computed } from "mobx";
import { Popup } from "../../shared/components/popup/Popup";
import { CourseStructure } from "../../shared/contentStructure/CourseStructure";
import { IUrl } from "../../shared/models/Url";
import { Product } from "../../shared/stores/Product";
import { ProductStore } from "../../shared/stores/ProductStore";
import { isDefined } from "../../shared/typeUtils";
import { ConfigDto } from "../../types/config/dto/ConfigDto";
import { TranslationsDto } from "../../types/translations/dto/TranslationsDto";
import { DynamicLink, SourceLink, StaticLink } from "../header/HeaderViewModel";

type FooterContext =
  | { context: "productNest"; productStore: ProductStore; config: ConfigDto }
  | { context: "product"; product: Product }
  | { context: "course"; product: Product; course: CourseStructure };

type LinksSection = {
  id: string;
  translation: keyof TranslationsDto;
  links: SourceLink[];
  accordion: Popup;
};

const isProductWithStudiesType = (product: Product): product is Product & { studiesType: string } => {
  if (!product.studiesType) {
    return false;
  }

  return true;
};

export class FooterViewModel {
  readonly currentYear = new Date().getFullYear();

  @computed get termsAndConditionsPageLink(): StaticLink | undefined {
    return {
      translation: "termsAndConditions",
      id: "termsAndConditions",
      href: this.url.toTermsAndConditionsPage(),
    };
  }

  @computed get shouldShowGyldendalLogo() {
    return this.currentContext.context !== "productNest";
  }

  @computed get logoLink(): StaticLink {
    if (this.currentContext.context === "course") {
      return {
        id: "toDashboard",
        href: this.url.toDashboardPage(this.currentContext.course.urlParams),
        translation: "goToDashboard",
      };
    }

    if (this.currentContext.context === "product") {
      return {
        id: "toProductPage",
        href: this.url.toProductPage(this.currentContext.product.productParams),
        translation: "goToHomePage",
      };
    }

    return {
      id: "toProductNest",
      href: this.url.toProductNestPage(),
      translation: "goToProductOverview",
    };
  }

  @computed private get studiesType() {
    if (this.currentContext.context === "productNest") {
      return undefined;
    }

    return this.currentContext.product.studiesType;
  }

  @computed get studiesTypeLink(): DynamicLink | undefined {
    if (!this.studiesType || this.currentContext.context === "productNest") {
      return;
    }

    return {
      title: this.studiesType,
      href: this.url.toProductPage(this.currentContext.product.productParams),
      id: "toProductPage",
    };
  }

  @computed private get exploreSection(): LinksSection | undefined {
    const links: SourceLink[] = [];
    const activeProduct = this.app.productStore.activeProduct;

    if (!activeProduct) {
      return;
    }

    const { productAuthorsHero, shouldShowProductArticleList, productParams } = activeProduct;

    if (productAuthorsHero) {
      links.push({
        source: "static",
        href: this.url.toAuthorsForProductPage(productParams),
        translation: "meetTheAuthors",
        id: "Meet the authors",
      });
    }

    if (shouldShowProductArticleList) {
      links.push({
        source: "static",
        href: this.url.toProductArticleListPage(productParams),
        translation: "articles",
        id: "Articles",
      });
    }

    if (links.length === 0) {
      return;
    }

    return {
      id: "Explore",
      translation: "explore",
      links,
      accordion: new Popup(),
    };
  }

  @computed private get practicalSection(): LinksSection | undefined {
    const { practicalArticles } = this.app.config;
    if (!practicalArticles) {
      return undefined;
    }

    const articlesLinks: SourceLink[] = practicalArticles.map(article => ({
      source: "dynamic",
      title: article.title,
      href: this.url.toArticlePage({ id: article.id, slug: article.slug }),
      id: article.id,
    }));

    const aboutUsLink: SourceLink[] = [
      {
        source: "static",
        href: this.url.toAboutUsPage(),
        translation: "aboutUs",
        id: "aboutUs",
      },
    ];

    const links: SourceLink[] = aboutUsLink.concat(articlesLinks);

    if (links.length === 0) {
      return;
    }

    return {
      id: "Practical",
      translation: "practical",
      links,
      accordion: new Popup(),
    };
  }

  @computed private get studiesSection(): LinksSection | undefined {
    if (this.hideProductList) {
      return;
    }

    const productLinks: SourceLink[] = this.app.productStore.products.filter(isProductWithStudiesType).map(p => ({
      source: "dynamic",
      title: p.studiesType,
      href: this.url.toProductPage(p.productParams),
      id: p.id,
    }));

    if (productLinks.length === 0) {
      return;
    }

    return {
      id: "Studies",
      translation: "studies",
      links: productLinks,
      accordion: new Popup(),
    };
  }

  @computed get linkSections(): LinksSection[] {
    const sections: (LinksSection | undefined)[] = [];

    if (this.currentContext.context === "productNest") {
      sections.push(this.practicalSection);
      sections.push(this.studiesSection);
    } else {
      sections.push(this.exploreSection);
      sections.push(this.practicalSection);
      sections.push(this.studiesSection);
    }

    return sections.filter(isDefined);
  }

  @computed get feedbackButtonLink() {
    return this.activeCourse?.feedbackButtonLink;
  }

  @computed get currentContext(): FooterContext {
    if (!this.activeProduct) {
      return { context: "productNest", config: this.app.config, productStore: this.app.productStore };
    }

    if (!this.activeCourse) {
      return { context: "product", product: this.activeProduct };
    }

    return { context: "course", course: this.activeCourse, product: this.activeProduct };
  }

  @computed private get activeProduct() {
    return this.app.productStore.activeProduct;
  }

  @computed private get activeCourse() {
    return this.activeProduct?.courseStructure;
  }

  @computed private get hideProductList() {
    return this.app.config.hideProductList;
  }

  constructor(private url: IUrl, private app: { productStore: ProductStore; config: ConfigDto }) {}
}
