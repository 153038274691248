import { computed, observable, action } from "mobx";
import { CourseInfo, Product } from "../../../shared/stores/Product";
import { BetaNewsletterPopupViewModel, NewsletterData } from "./betaNewsletterPopup/BetaNewsletterPopupViewModel";

export class ProductHeroViewModel {
  @observable betaNewsletterModel = new BetaNewsletterPopupViewModel(this.handleNewsletterSubmit);

  @computed get courses() {
    return this.product.courses;
  }

  @computed get title() {
    return this.product.heroSection.title;
  }

  @computed get description() {
    return this.product.heroSection.description;
  }

  @computed get image() {
    return this.product.image;
  }

  @computed get isBeta() {
    return this.product.isBeta;
  }

  @action.bound
  private startCourseNewsletterRegistration(course: CourseInfo) {
    this.betaNewsletterModel.showNewsletterForm(course);
  }

  @action.bound
  onCourseClick(slug: string) {
    this.handleCourseClick(slug);

  }

  constructor(
    public coursesSectionId: string,
    private product: Product,
    private handleCourseClick: (slug: string) => void,
    private handleNewsletterSubmit: (course: CourseInfo, formData: NewsletterData) => void
  ) {}

  getCourseBySlug = (courseSlug: string) => {
    const course = this.product.courses.find(c => c.slug === courseSlug);

    if (!course) {
      throw new Error("Cannot find course in the product");
    }

    return course;
  };
}
