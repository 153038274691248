import { action, computed, observable } from "mobx";
import { PaymentSidePanelViewModel } from "../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { ITibetClient } from "../../shared/api/TibetClient";
import { HrefType } from "../../shared/components/link/Link";
import { Popup } from "../../shared/components/popup/Popup";
import { IUrl } from "../../shared/models/Url";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILowLevelNavigationService } from "../../shared/services/NavigationService";
import { ProductStore } from "../../shared/stores/ProductStore";
import { ConfigDto } from "../../types/config/dto/ConfigDto";
import { TranslationsDto } from "../../types/translations/dto/TranslationsDto";
import { HeaderHamburgerViewModel } from "./headerHamburger/HeaderHamburgerViewModel";

export interface BaseLink {
  id: string;
  href: string;
  type?: HrefType;
  target?: "_blank" | "_self";
}
export type StaticLink = BaseLink & { translation: keyof TranslationsDto };

export type DynamicLink = BaseLink & {
  title: string;
};

export type SourceLink = ({ source: "static" } & StaticLink) | ({ source: "dynamic" } & DynamicLink);

export type SearchLink = {
  toSearchPage: (searchPhrase?: string) => void;
  translation: keyof TranslationsDto;
};

export type UserLink = StaticLink & { icon: { name: string; color: "primary" | "secondary" } };

export class HeaderViewModel {
  @observable public userLinksSheet = new Popup();

  @observable public userLinksDialog = new Popup();

  @observable pickCourseSidePanel = new Popup();

  @observable motif = window.motif;

  @computed get availableCoursesCount() {
    return this.activeProduct?.availableCourses.length || 0;
  }

  @computed get logoLink(): StaticLink {
    if (this.activeCourseParams) {
      return {
        id: "toDashboard",
        href: this.url.toDashboardPage(this.activeCourseParams),
        translation: "goToDashboard",
      };
    }

    if (this.activeProduct) {
      return {
        id: "toProductPage",
        href: this.url.toProductPage(this.activeProduct.productParams),
        translation: "goToHomePage",
      };
    }

    return { id: "toProductNest", href: this.url.toProductNestPage(), translation: "goToProductOverview" };
  }

  @computed get userLinks() {
    if (!this.isAuthenticated) {
      return;
    }
    // TODO: Remove link to BPN when content is merged to one site.
    const links: UserLink[] = [
      {
        href: this.motif == "riktig" ? this.url.toMyProfile() : this.url.toMyProfileBPN(),
        translation: "myProfile",
        id: "myProfile",
        type: HrefType.External,
        target: "_blank",
        icon: { name: "user", color: "primary" },
      },
    ];

    if (this.activeCourseParams) {
      links.push({
        id: "bookmarksLink",
        href: this.url.toBookmarksPage(this.activeCourseParams),
        translation: "bookmarksLink",
        icon: { name: "bookmark", color: "primary" },
      });
    }

    links.push({
      id: "logOut",
      href: this.url.toLogoutPage(),
      translation: "logOut",
      type: HrefType.External,
      icon: { name: "log-out", color: "secondary" },
    });

    return links;
  }

  @computed get searchLink(): StaticLink | undefined {
    if (!this.activeCourseParams) {
      return;
    }

    return {
      id: "search",
      href: this.url.toSearchPage(this.activeCourseParams),
      translation: "search",
    };
  }

  @computed get paymentSidePanel() {
    if (!this.activeProduct?.courseStructure) {
      return;
    }

    if (this.activeCourse?.hasFullAccess) {
      return;
    }

    return new PaymentSidePanelViewModel(
      this.app.tibetClient,
      this.activeProduct.courseStructure,
      this.errorService,
      this.app.navigation
    );
  }

  @computed get activeProduct() {
    return this.app.productStore.activeProduct;
  }

  @computed get activeCourse() {
    return this.activeProduct?.courseStructure;
  }

  @computed get isAuthenticated() {
    return this.app.isAuthenticated;
  }

  @computed private get activeCourseParams() {
    if (!this.activeCourse) {
      return;
    }

    return this.activeCourse.urlParams;
  }

  @computed get headerHamburgerModel() {
    return new HeaderHamburgerViewModel(
      this.url,
      this.baseHeaderLinks,
      this.goToSearchPage,
      this.app.config,
      this.searchLink,
      this.activeProduct,
      this.activeCourse,
      this.paymentSidePanel
    );
  }

  @computed private get trainingGroundLink(): SourceLink | undefined {
    if (!this.activeCourse) {
      return;
    }

    const { trainingGroundHeaderInfo, urlParams } = this.activeCourse;

    if (!trainingGroundHeaderInfo.trainingGroundInfos || trainingGroundHeaderInfo.trainingGroundInfos.length === 0) {
      return;
    }

    if (!trainingGroundHeaderInfo.trainingGroundHeaderText) {
      return;
    }

    return {
      id: "trainingGroundLink",
      href: this.url.toTrainingGroundPage(urlParams, trainingGroundHeaderInfo.trainingGroundInfos[0].slug),
      title: trainingGroundHeaderInfo.trainingGroundHeaderText,
      source: "dynamic",
    };
  }

  @computed get baseHeaderLinks(): SourceLink[] {
    const links: SourceLink[] = [];

    if (!this.activeCourseParams) {
      return links;
    }

    links.push({
      id: "dashboard",
      href: this.url.toDashboardPage(this.activeCourseParams),
      translation: "dashboard",
      source: "static",
    });

    links.push({
      id: "themesLink",
      href: this.url.toThemesPage(this.activeCourseParams),
      translation: "themesLink",
      source: "static",
    });

    if (this.trainingGroundLink) {
      links.push(this.trainingGroundLink);
    }

    if (this.activeCourse?.previousExamsStructure) {
      links.push({
        id: "previousExams",
        href: this.url.toPreviousExamsPage(this.activeCourseParams),
        translation: "previousExams",
        source: "static",
      });
    }

    return links;
  }

  constructor(
    private url: IUrl,
    private errorService: IErrorService,
    private app: {
      isAuthenticated: boolean;
      productStore: ProductStore;
      navigation: ILowLevelNavigationService;
      tibetClient: ITibetClient;
      gtm: IGtmService;
      config: ConfigDto;
    },
    public handleLogin: (slug?: string) => Promise<void>
  ) {}

  @action.bound
  goToSearchPage(searchPhrase: string) {
    if (!this.activeCourseParams) {
      return;
    }

    return this.app.navigation.goToSearch(searchPhrase, this.activeCourseParams);
  }
}
