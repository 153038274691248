import shuffle from "lodash/shuffle";

export type GapTaskOption = { "nb-NO": string };

export const shuffledOptions = (choices: GapTaskOption[], additionalChoices: GapTaskOption[] = []) => {
  console.log("choices", choices);
  console.log("additchoices", additionalChoices);
  const gapTestChoices = additionalChoices
    .concat(choices)
    .map(option => ({ value: option["nb-NO"], label: option["nb-NO"] }));
  return shuffle(gapTestChoices);
};

export const isAnswerCorrect = (chosenValue: string, correctChoices: string[]) => {
  return correctChoices.map(correctChoice => chosenValue === correctChoice).includes(true);
};
