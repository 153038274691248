import { observer } from "mobx-react";
import * as React from "react";
import { ActivityIndicator } from "../../../shared/components/activityIndicator/ActivityIndicator";
import { SectionArticleListBlock } from "../../../shared/components/blocks/SectionArticleListBlock/SectionArticleListBlock";
import { PageTitle } from "../../../shared/components/pageTitle/PageTitle";
import { PortableTextContent } from "../../../shared/components/portableTextContent/PortableTextContent";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { FormattedText } from "../../../shared/textUtils";
import { useHistory, useUrl } from "../../../useRouter";
import { AuthorDetailsSidePanel } from "../../authorsForProduct/authorDetailsSidePanel/AuthorDetailsSidePanel";
import { useProductParams } from "../../product/ProductParamsContext";
import { ArticleViewModel } from "./ArticleViewModel";
import { ArticleBuySection } from "./articleBuySection/ArticleBuySection";
import { ArticleHero } from "./articleHero/ArticleHero";
import { ArticleLoginSection } from "./articleLoginSection/ArticleLoginSection";
import { ArticleSummary } from "./articleSummary/ArticleSummary";

export const ArticleView = observer(({ model }: { model: ArticleViewModel }) => {
  const { article, actionSection, authorInfo, authorInfoPopup, setSelectedAuthor } = model;
  const history = useHistory();
  const url = useUrl();
  const translations = useTranslations();
  const productParams = useProductParams();
  const fallbackLink = productParams
    ? { href: url.toProductArticleListPage(productParams), text: translations.goToArticleListPage }
    : undefined;

  const backLink =
    history.location.state?.goBackHref && history.location.state?.goBackText
      ? { href: history.location.state.goBackHref, text: history.location.state.goBackText }
      : undefined;

  if (!article) {
    return (
      <article className="Article">
        <ActivityIndicator className="Article__loader" isLoading />
      </article>
    );
  }

  const {
    seoTitle,
    title,
    seoDescription,
    category,
    image,
    updatedAt,
    content,
    authors,
    description,
    loginActionSection,
    buyActionSection,
    summary,
    relatedArticles,
  } = article;

  return (
    <PageTitle title={seoTitle || title} description={seoDescription || description} image={image} type="article">
      <article className="Article">
        <div className="Article__wrapper">
          <ArticleHero
            title={title}
            category={category}
            image={image}
            description={description}
            authors={authors}
            updatedAt={updatedAt}
            backLink={backLink || fallbackLink}
            setSelectedAuthor={setSelectedAuthor}
          />
          <div className="Article__inner">
            <div className="Article__content">
              {summary && (
                <div className="Article__summaryWrapper">
                  <ArticleSummary summary={summary} />
                </div>
              )}
              <div className="Article__contentInner">
                <PortableTextContent content={content} />
              </div>
            </div>
          </div>
          {actionSection && (
            <div className="Article__actionSection">
              {actionSection.type === "LOGIN" ? (
                <ArticleLoginSection model={actionSection} section={loginActionSection} />
              ) : (
                <ArticleBuySection model={actionSection} section={buyActionSection} />
              )}
            </div>
          )}
          {relatedArticles && relatedArticles.length > 0 && (
            <SectionArticleListBlock
              title={translations.youMightAlsoLike}
              articles={relatedArticles}
              goBackText={FormattedText(translations.goToX, [title])}
              productParams={productParams}
            />
          )}
        </div>
        <AuthorDetailsSidePanel popup={authorInfoPopup} authorInfo={authorInfo} />
      </article>
    </PageTitle>
  );
});
