import head from "lodash/head";
import last from "lodash/last";
import { computed, observable, observe, reaction } from "mobx";
import { asyncCommand, command, Command } from "react-mvvm";
import { IVideoService } from "../../../shared/api/BackendApi";
import { Popup } from "../../../shared/components/popup/Popup";
import { BookmarkToggleViewModel } from "../../../shared/components/thumbnails/shared/BookmarkToggleViewModel";
import { createStepThumbnailViewModel } from "../../../shared/components/thumbnails/stepThumbnail/createStepThumbnailViewModel";
import { LessonStructure } from "../../../shared/contentStructure/LessonStructure";
import { StepStructure } from "../../../shared/contentStructure/StepStructure";
import { IErrorService } from "../../../shared/services/ErrorService";
import { IGtmService } from "../../../shared/services/GtmService";
import { ILanguageService } from "../../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../../shared/services/NavigationService";
import { BookmarkStore } from "../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../shared/stores/ProgressStore";
import { IUserStore } from "../../../shared/stores/UserStore";
import { BasePage } from "../../BasePage";
import { IAppMotifProvider } from "../../shared/AppMotif";
import { CourseLevel } from "../../shared/courseLevel/CourseLevel";
import { CourseNavigator } from "../../shared/courseNavigator/CourseNavigator";
import { PaymentSidePanelViewModel } from "../../shared/paymentDialog/PaymentSidePanelViewModel";
import { StepStore } from "../StepStore";
import { AddBookmarkDialogViewModel } from "./bookmarkDialog/addBookmarkDialog/AddBookmarkDialogViewModel";
import { RemoveBookmarkDialogViewModel } from "./bookmarkDialog/removeBookmarkDialog/RemoveBookmarkDialogViewModel";
import { CongratulationPopupViewModel } from "./congratulationPopup/CongratulationPopupViewModel";
import { LevelSidePanelViewModel } from "./levelSidePanel/LevelSidePanelViewModel";
import { StepListItem } from "./stepsList/StepListItem";

export interface StepNavigationItem {
  slug: string;
  hasAccess: boolean;
}

export class LessonViewModel extends BasePage implements IAppMotifProvider {
  @observable currentStepSlug: string;

  @observable congratulationPopupViewModel: CongratulationPopupViewModel;

  @observable levelSidePanelViewModel?: LevelSidePanelViewModel;

  @observable stepsListPanel: Popup;

  showBookmarkModal: Command;

  goToNextStep: Command;

  goToPreviousStep: Command;

  toggleStepCompletion: Command<boolean, void>;

  goToStep: Command<StepNavigationItem>;

  @computed get currentStep() {
    return this.stepStore.bySlug.get(this.currentStepSlug);
  }

  @computed get currentStepStructure() {
    const stepStructure = this.lesson.stepStructures.find(structure => structure.slug === this.currentStepSlug);

    if (!stepStructure) {
      throw new Error("All steps should have StepStructure");
    }

    return stepStructure;
  }

  @computed get steps() {
    return this.lesson.stepStructures
      .map(step => {
        const { id, slug, title, thumbnail } = step;
        const thumbnailViewModel = createStepThumbnailViewModel(thumbnail, this.progressStore, this.videoService);
        return new StepListItem({ id, slug, title }, thumbnailViewModel);
      })
      .filter(step => step.slug !== undefined);
  }

  @computed get stepList() {
    return this.lesson.stepStructures;
  }

  @computed get stepsCount() {
    return this.steps.length;
  }

  @computed get completedStepsCount() {
    return this.lessonProgress.completedStepsCount;
  }

  @computed get isLessonCompleted() {
    return this.lessonProgress.isCompleted;
  }

  @computed get isLastStep() {
    return this.currentStep?.id === last(this.steps)?.id;
  }

  @computed get isFirstStep() {
    return this.currentStep?.id === head(this.steps)?.id;
  }

  @computed get nextStep() {
    return this.courseNavigator.getNextStep(this.currentStepStructure);
  }

  @computed get previousStep() {
    return this.courseNavigator.getPreviousStep(this.currentStepStructure);
  }

  @computed get appMotif() {
    return { color: this.lesson.color };
  }

  @computed get lessonProgress() {
    const parentThemeId = this.lesson.themeStructure.id;
    return this.progressStore.courseProgress.getThemeProgressById(parentThemeId).getLessonProgressById(this.lesson.id);
  }

  @computed get relatedContentThumbnails() {
    if (this.currentStep?.relatedContentIds === undefined) {
      return [];
    }

    return this.currentStep.relatedContentIds
      .map(id => this.courseStructure.allSteps.find(step => step.id === id))
      .filter((stepStructure): stepStructure is StepStructure => !!stepStructure)
      .map(stepStructure =>
        createStepThumbnailViewModel(
          stepStructure.thumbnail,
          this.progressStore,
          this.videoService,
          new BookmarkToggleViewModel(stepStructure.id, this.bookmarkStore, this.userStore)
        )
      );
  }

  @computed get isAuthenticated() {
    return this.userStore.isAuthenticated;
  }

  @computed get themeSlug() {
    return this.lesson.themeStructure.slug;
  }

  @computed get courseStructure() {
    return this.lesson.themeStructure.courseStructure;
  }

  @computed get addBookmarkDialogViewModel() {
    return new AddBookmarkDialogViewModel(this.currentStepStructure.id, this.bookmarkStore);
  }

  @computed get removeBookmarkDialogViewModel() {
    return new RemoveBookmarkDialogViewModel(this.currentStepStructure.id, this.bookmarkStore);
  }

  constructor(
    stepSlug: string,
    public lesson: LessonStructure,
    public stepStore: StepStore,
    public videoService: IVideoService,
    public progressStore: IProgressStore,
    public bookmarkStore: BookmarkStore,
    public userStore: IUserStore,
    languageService: ILanguageService,
    errorService: IErrorService,
    public paymentSidePanelViewModel: PaymentSidePanelViewModel,
    public navigationService: ILowLevelNavigationService,
    public gtm: IGtmService,
    public courseNavigator: CourseNavigator
  ) {
    super(languageService, errorService);

    reaction(
      () => this.currentStepSlug,
      async () => {
        this.isLoading = true;
        await stepStore.load(this.currentStepSlug);
        this.isLoading = false;
      }
    );

    this.currentStepSlug = stepSlug;
    this.congratulationPopupViewModel = new CongratulationPopupViewModel(this);

    this.showBookmarkModal = command(() =>
      !this.currentStep?.isBookmarked
        ? this.addBookmarkDialogViewModel.show()
        : this.removeBookmarkDialogViewModel.show()
    );
    this.goToStep = asyncCommand(async (newStep: StepNavigationItem) => {
      if (!newStep.hasAccess) {
        this.paymentSidePanelViewModel.show();
        return;
      }

      this.currentStepSlug = newStep.slug;
      this.stepsListPanel.close();
      this.addBookmarkDialogViewModel.hide();
      this.removeBookmarkDialogViewModel.hide();
      this.paymentSidePanelViewModel.close();
    });
    this.goToNextStep = command(
      () => {
        if (this.isLastStep) {
          this.congratulationPopupViewModel.show();
          return;
        }

        if (this.nextStep === undefined) {
          return;
        }

        this.goToStep.execute(this.nextStep);
      },
      () => !this.congratulationPopupViewModel.isOpen && (this.isLastStep || this.nextStep !== undefined)
    );
    this.goToPreviousStep = command(
      () => {
        if (this.previousStep === undefined) {
          return;
        }

        this.goToStep.execute(this.previousStep);
      },
      () => !!this.previousStep
    );

    this.toggleStepCompletion = asyncCommand(
      async (done: boolean) => {
        if (!this.currentStep) {
          return;
        }

        if (done) {
          await this.currentStep.markStepAsCompleted();
        } else {
          await this.currentStep.markStepAsUncompleted();
        }
      },
      () => this.currentStep !== undefined
    );

    this.stepsListPanel = new Popup();
  }

  protected async loadData() {
    this.isLoading = false;
  }

  async onActivated() {
    if (this.courseStructure.levels) {
      const courseLevel = new CourseLevel(this.progressStore, this.courseStructure.levels);

      this.levelSidePanelViewModel = new LevelSidePanelViewModel(this.progressStore.courseProgress, courseLevel);

      observe(courseLevel, "level", ({ newValue, oldValue }) => {
        if (oldValue && newValue && newValue.order > oldValue.order) {
          this.levelSidePanelViewModel?.show();
        }
      });
    }
    super.onActivated();
  }
}
