import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import { action, computed } from "mobx";
import { IVideoService } from "../../shared/api/BackendApi";
import { ThemeItem } from "../../shared/components/basicThemesSection/ThemeItem";
import { SelectViewModel } from "../../shared/components/select/SelectViewModel";
import { CourseStructure } from "../../shared/contentStructure/CourseStructure";
import { Url } from "../../shared/models/Url";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../shared/services/NavigationService";
import { IProgressStore } from "../../shared/stores/ProgressStore";
import { PreviousExamTypeDto } from "../../types/courses/dto/PreviousExamTypeDto";
import { ImageDto } from "../../types/shared/dto/ImageDto";
import { GtmBasePage } from "../GtmBasePage";

export interface ExamTheme {
  id: string;
  theme: ThemeItem;
  examYear: number;
  examTypes: PreviousExamTypeDto[];
  thumbnailImage?: ImageDto;
}

export class PreviousExamsViewModel extends GtmBasePage {
  yearsSelect: SelectViewModel<string>;

  typesSelect: SelectViewModel<string>;

  @computed get previousExamsStructure() {
    if (!this.courseStructure.previousExamsStructure) {
      throw new Error("Previous exams structure should be specified in the Course Structure");
    }

    return this.courseStructure.previousExamsStructure;
  }

  @computed get courseParams() {
    return this.courseStructure.urlParams;
  }

  @computed get exams(): ExamTheme[] {
    return this.previousExamsStructure.examThemes.map(examTheme => ({
      ...examTheme,
      theme: new ThemeItem(examTheme.theme, this.progressStore, this.videoService),
    }));
  }

  @computed get examsByYear() {
    return groupBy(this.exams, exam => exam.examYear);
  }

  @computed get examsYears() {
    /*
      Descending sorting because object keys are sorted in ascending order by default
      (and we want the latest year to be at the top)
    */
    return Object.keys(this.examsByYear).sort((a, b) => {
      const aNumber = Number(a) || 0;
      const bNumber = Number(b) || 0;

      return bNumber - aNumber;
    });
  }

  @computed get areFiltersApplied() {
    return this.yearsSelect.value.concat(this.typesSelect.value).length > 0;
  }

  @computed get filteredItems() {
    const yearsFiltered = this.yearsSelect.hasValue
      ? this.exams.filter(exam => this.yearsSelect.value.includes(exam.examYear.toString()))
      : this.exams;

    const typesFiltered = this.typesSelect.hasValue
      ? yearsFiltered.filter(exam =>
          exam.examTypes.map(examType => examType.name).some(examTypeId => this.typesSelect.value.includes(examTypeId))
        )
      : yearsFiltered;

    return typesFiltered;
  }

  @action.bound
  resetFilters() {
    this.yearsSelect.resetValue();
    this.typesSelect.resetValue();
  }

  constructor(
    public languageService: ILanguageService,
    public gtm: IGtmService,
    public errorService: IErrorService,
    private courseStructure: CourseStructure,
    private navigation: ILowLevelNavigationService,
    private progressStore: IProgressStore,
    private videoService: IVideoService
  ) {
    super(languageService, gtm, errorService);

    this.yearsSelect = new SelectViewModel<string>(
      this.examsYears.map(examsYear => ({ value: examsYear, label: examsYear }))
    );

    const types = uniqBy(flatten(this.exams.map(exam => exam.examTypes)), type => type.name);
    this.typesSelect = new SelectViewModel<string>(types.map(type => ({ value: type.name, label: type.name })));
  }

  protected async loadData() {
    if (!this.courseStructure.previousExamsStructure) {
      const dashboardUrl = Url.toDashboardPage(this.courseStructure.urlParams);
      this.navigation.redirectTo(dashboardUrl);
    }
  }
}
