import { computed, observable, action, when } from "mobx";
import { command, Command, Deferred, isLoaded } from "react-mvvm";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { MaskDto } from "../../../../types/shared/dto/MaskDto";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { delay, loadImages } from "../../../utils";
import { AnswersState } from "../AnswersState";
import { InteractiveTask } from "../InteractiveTask";
import { MaskViewModel } from "./MaskViewModel";
import { MaskSelectionStrategy } from "./maskStrategy/MaskSelectionStrategy";
import { MultipleChoiceMaskStrategy } from "./maskStrategy/MultipleChoiceMaskStrategy";
import { SingleChoiceMaskStrategy } from "./maskStrategy/SingleChoiceMaskStrategy";

export type ImageType = HTMLImageElement;

export const MAX_MASK_SIZE = 1500;

export type MaskTaskState =
  | { type: "loading" }
  | { type: "error" }
  | { type: "selectMasks"; image: ImageType; masks: MaskViewModel[] }
  | { type: "correct"; image: ImageType; masks: MaskViewModel[]; selectedMasks: MaskViewModel[] }
  | { type: "partiallyCorrect"; image: ImageType; masks: MaskViewModel[]; selectedMasks: MaskViewModel[] }
  | { type: "incorrect"; image: ImageType; masks: MaskViewModel[]; selectedMasks: MaskViewModel[] };

export class MaskTaskViewModel implements InteractiveTask {
  get stage() {
    if (isLoaded(this.image)) {
      return { width: this.image.width, height: this.image.height };
    }

    return { width: 0, height: 0 };
  }

  @observable private image: Deferred<ImageType> = "Loading";

  @observable isDisabled = false;

  @observable masks: MaskViewModel[];

  @computed get selectionStrategy(): MaskSelectionStrategy {
    return this.isSingleChoice ? new SingleChoiceMaskStrategy(this) : new MultipleChoiceMaskStrategy();
  }

  @computed private get isLoading() {
    return this.masks.some(mask => mask.state.type === "loading") || this.image === "Loading";
  }

  @computed private get isError() {
    return this.masks.some(mask => mask.state.type === "error") || this.image === "LoadingError";
  }

  @computed private get isCorrect() {
    return (
      this.masks.filter(mask => mask.isCorrect).every(mask => mask.state.type === "correct") &&
      this.masks.filter(mask => !mask.isCorrect && mask.state.type === "incorrect").length === 0
    );
  }

  @computed private get isPartiallyCorrect() {
    const isAnyCorrect = this.masks.some(mask => mask.state.type === "correct");

    if (!isAnyCorrect) {
      return false;
    }

    const incorrectMasksLength = this.masks.filter(mask => mask.state.type === "incorrect").length;
    const correctMasksLength = this.masks.filter(mask => mask.state.type === "correct").length;
    const totalCorrectMasksLength = this.masks.filter(mask => mask.isCorrect).length;

    return incorrectMasksLength > 0 || correctMasksLength < totalCorrectMasksLength;
  }

  @computed private get isIncorrect() {
    return this.masks.some(mask => mask.state.type === "incorrect");
  }

  @computed get isAnswered() {
    return this.masks.some(
      mask => mask.state.type === "selected" || mask.state.type === "incorrect" || mask.state.type === "correct"
    );
  }

  private get isSingleChoice() {
    const correctMasks = this.maskDtos.filter(mask => mask.isCorrect);
    return correctMasks.length === 1;
  }

  @observable selectedMasks: MaskViewModel[] = [];

  @computed get state(): MaskTaskState {
    if (this.isError) {
      return { type: "error" };
    }

    if (this.isLoading || !isLoaded(this.image)) {
      return { type: "loading" };
    }

    if (this.isCorrect) {
      return { type: "correct", image: this.image, masks: this.masks, selectedMasks: this.selectedMasks };
    }

    if (this.isPartiallyCorrect) {
      return { type: "partiallyCorrect", image: this.image, masks: this.masks, selectedMasks: this.selectedMasks };
    }

    if (this.isIncorrect) {
      return { type: "incorrect", image: this.image, masks: this.masks, selectedMasks: this.selectedMasks };
    }

    return { type: "selectMasks", image: this.image, masks: this.masks };
  }

  @computed get answersState(): AnswersState {
    if (this.state.type === "correct") return "correct";
    if (this.state.type === "partiallyCorrect") return "partiallyCorrect";
    if (this.state.type === "incorrect") return "wrong";
    return "default";
  }

  @computed get isSubmitted() {
    return this.answersState !== "default";
  }

  @action.bound
  async showCorrectAnswer() {
    await when(() => this.isLoading === false);
    this.isDisabled = false;
    const correctMasks = this.masks.filter(mask => mask.isCorrect);
    this.masks.forEach(mask => mask.reset());
    correctMasks.forEach(mask => mask.toggleSelection());
    correctMasks.forEach(mask => mask.submit());
    this.selectedMasks = correctMasks;
    this.isDisabled = true;
  }

  refresh: Command;

  readonly imageUrl: string;

  constructor(
    public id: string,
    public title: string,
    private maskDtos: MaskDto[],
    private baseImageDto: ImageDto,
    public readonly content: PortableTextDto,
    public description?: string
  ) {
    this.masks = this.maskDtos.map(mask => new MaskViewModel(mask, this));

    this.refresh = command(
      () => {
        this.image === "LoadingError" && this.load();
        this.masks.forEach(mask => mask.state.type === "error" && mask.load());
      },
      () => this.state.type === "error"
    );

    if (!baseImageDto.url) {
      throw new Error("Not defined image (Mask Task)");
    }

    this.imageUrl = `${baseImageDto.url}?w=${MAX_MASK_SIZE}`;
  }

  @action.bound
  async reset() {
    await when(() => this.isLoading === false);
    this.masks.forEach(mask => mask.reset());
    this.selectedMasks = [];
    this.isDisabled = false;
  }

  @action.bound
  submit() {
    const selectedMasks = this.masks.filter(mask => mask.state.type === "selected");
    this.selectedMasks = selectedMasks;
    selectedMasks.forEach(mask => mask.submit());

    const shouldBeCheckedMasks = this.masks.filter(mask => mask.isCorrect && mask.state.type === "notSelected");
    shouldBeCheckedMasks.map(mask => mask.submit());
  }

  @action.bound
  async highlightMasks() {
    const notSelectedMasks = this.masks.filter(mask => mask.state.type === "notSelected");
    notSelectedMasks.forEach(mask => mask.highlight());

    await delay(800);

    const highlightedMasks = this.masks.filter(mask => mask.state.type === "highlighted");
    highlightedMasks.forEach(mask => mask.reset());
  }

  @action.bound
  public async load() {
    if (this.image !== "Loading" && this.image !== "LoadingError") {
      return;
    }

    try {
      this.image = "Loading";
      const [loadedImage] = await loadImages([this.imageUrl]);
      this.image = loadedImage;
    } catch {
      this.image = "LoadingError";
    }
  }
}
