import React from "react";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { Button } from "../button/Button";

export interface CourseButtonProps {
  courseSlug: string;
  onClick: (slug: string) => void;
}

export const CourseButton: React.FC<CourseButtonProps> = ({ courseSlug, onClick }) => {
  const translations = useTranslations();

  return (
    <Button
      type="solid"
      size="large"
      className="CourseButton"
      onClick={() => {
        onClick(courseSlug);
      }}
    >
      <span>{translations.goToProduct}</span>
    </Button>
  );
};
