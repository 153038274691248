import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { ProductParams } from "../../../../pages/product/addProductRouting";
import { ArticleReferenceDto } from "../../../../types/config/dto/ArticleReferenceDto";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { useUrl } from "../../../../useRouter";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { SanityImage } from "../../image/Image";
import { Link } from "../../link/Link";

export interface SectionImageBlockProps {
  title: string;
  titleImage?: ImageDto;
  description?: string;
  imagePosition: number;
  primaryImage: ImageDto;
  secondaryImage?: ImageDto;
  article?: ArticleReferenceDto;
  productParams?: ProductParams;
  buttonText?: string;
  isCrateVisible?: boolean;
}
export const SectionImageBlock = observer(
  ({
    title,
    titleImage,
    description,
    imagePosition,
    primaryImage,
    secondaryImage,
    article,
    productParams,
    buttonText,
    isCrateVisible = false,
  }: SectionImageBlockProps) => {
    const url = useUrl();
    const translations = useTranslations();
    const isImageLeft = imagePosition === 1;

    return (
      <div
        className={classNames(
          "SectionImageBlock",
          { "SectionImageBlock--imageLeft": isImageLeft },
          { "SectionImageBlock--crateVisible": isCrateVisible }
        )}
      >
        <div className="SectionImageBlock__inner">
          <div className="SectionImageBlock__content">
            <div className="SectionImageBlock__contentInner">
              <div
                className={classNames("SectionImageBlock__titleContainer", {
                  "SectionImageBlock__titleContainer--noImage": titleImage?.url,
                })}
              >
                {titleImage && (
                  <div className="SectionImageBlock__titleImageContainer">
                    <SanityImage {...titleImage} />
                  </div>
                )}
                <h2 className="SectionImageBlock__title">{title}</h2>
              </div>
              <p className="SectionImageBlock__description">{description}</p>
              {article && (
                <Link
                  to={productParams ? url.toProductArticlePage(productParams, article) : url.toArticlePage(article)}
                  type="button"
                  buttonVariant="primary"
                  className="SectionImageBlock__articleLink"
                  goBackText={translations.goToHomePage}
                >
                  {buttonText || translations.readMoreArticle}
                </Link>
              )}
            </div>
          </div>
          <div className="SectionImageBlock__imageContainer">
            <div className="SectionImageBlock__primaryImageContainer">
              <SanityImage {...primaryImage} />
            </div>
            {secondaryImage && (
              <div className="SectionImageBlock__secondaryImageContainer">
                <SanityImage {...secondaryImage} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
