import Cookies from "js-cookie";
import * as React from "react";
import { animated, useSpring } from "react-spring";
import { Button } from "../../../../shared/components/button/Button";
import { Collapse } from "../../../../shared/components/collapse/Collapse";
import { Link } from "../../../../shared/components/link/Link";
import { PortableTextContent } from "../../../../shared/components/portableTextContent/PortableTextContent";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { LinkDto } from "../../../../types/shared/dto/LinkDto";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { useUrl } from "../../../../useRouter";

export const InfoBannerCookieName = "ga_cookie_wasInfoBannerClosed";
const InfoBannerValue = "true";

export interface InfoBannerProps {
  userId: number;
  title?: string;
  description?: PortableTextDto;
  link?: LinkDto;
  courseParams?: ActiveCourseParams;
  goBackText?: string;
}

export const InfoBanner: React.FC<InfoBannerProps> = ({
  userId,
  title,
  description,
  link,
  courseParams,
  goBackText,
}) => {
  const url = useUrl();
  const translations = useTranslations();
  const infoBannerCookieName = `${InfoBannerCookieName}_${userId}`;
  const [infoBannerState, setInfoBannerState] = React.useState(Cookies.get(infoBannerCookieName));
  const infoBannerOpened = infoBannerState !== InfoBannerValue;
  const infoBannerStyles = useSpring({
    from: { translateX: "100vw" },
    translateX: infoBannerOpened ? "0vw" : "100vw",
  });

  const linkUrl = React.useMemo(() => {
    if (!link) return;
    const { article } = link;
    if (!article) return;
    return courseParams ? url.toCourseArticlePage(courseParams, article) : url.toArticlePage(article);
  }, []);

  const htmlId = `InfoBanner__${userId}`;

  const closeInfoBanner = () => {
    Cookies.set(infoBannerCookieName, InfoBannerValue, { expires: 150 });
    setInfoBannerState(InfoBannerValue);
  };

  return (
    <Collapse id={htmlId} isOpen={infoBannerOpened} aria-hidden={!infoBannerOpened}>
      <animated.section className="InfoBanner" style={infoBannerStyles}>
        <div className="InfoBanner__inner">
          <h2 className="InfoBanner__title">{title}</h2>
          {description && (
            <div className="InfoBanner__description">
              <PortableTextContent content={description} />
            </div>
          )}
          {linkUrl && (
            <Link className="InfoBanner__link" to={linkUrl} type="button" goBackText={goBackText}>
              {link?.title}
            </Link>
          )}
        </div>
        <div className="InfoBanner__buttonWrapper">
          <Button
            onClick={closeInfoBanner}
            type="circle"
            variant="secondary"
            size="xlarge"
            aria-expanded={infoBannerOpened}
            aria-controls={htmlId}
            className="InfoBanner__button"
          >
            <SvgIcon iconName="close" />
            <span className="visuallyHidden">{translations.close}</span>
          </Button>
        </div>
      </animated.section>
    </Collapse>
  );
};
