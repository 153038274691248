import { computed } from "mobx";
import { Popup } from "../../shared/components/popup/Popup";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { TermsAndConditionsDto } from "../../types/config/dto/TermsAndConditionsDto";
import { GtmBasePage } from "../GtmBasePage";

export class TermsAndConditionsViewModel extends GtmBasePage {
  @computed get termsAndConditionsAccordion() {
    if (!this.termsAndConditions) {
      return undefined;
    }

    return this.termsAndConditions.accordion.accordionItems.map(acc => ({ ...acc,id: "1234", model: new Popup() }));
  }

  @computed get termsAndConditionsInfo(): Omit<TermsAndConditionsDto, "accordion"> | undefined {
    if (!this.termsAndConditions) {
      return;
    }

    return this.termsAndConditions;
  }

  constructor(
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public gtm: IGtmService,
    private termsAndConditions?: TermsAndConditionsDto
  ) {
    super(languageService, gtm, errorService);
  }

  protected async loadData() {
    this.isLoading = false;
  }
}
