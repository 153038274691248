import isEqual from "lodash/isEqual";
import { observer } from "mobx-react";
import React from "react";
import { ContentView } from "react-mvvm";
import { animated, useSpring } from "react-spring";
import { ActivityIndicator } from "../../../shared/components/activityIndicator/ActivityIndicator";
import { SectionArticleItem } from "../../../shared/components/blocks/SectionArticleListBlock/SectionArticleItem/SectionArticleItem";
import { Image } from "../../../shared/components/image/Image";
import { List } from "../../../shared/components/list/List";
import { PageTitle } from "../../../shared/components/pageTitle/PageTitle";
import { Select } from "../../../shared/components/select/Select";
import { Title } from "../../../shared/components/title/Title";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import heroImageUrl from "../../../static/images/chatting-people.png";
import emptyListImageUrl from "../../../static/images/dog.png";
import { ArticleListViewModel } from "./ArticleListViewModel";

interface ArticleListViewProps {
  model: ArticleListViewModel;
}

export const ArticleListView = observer((props: ArticleListViewProps) => {
  const { model } = props;
  const { articles: _articles, categoriesSelect, allCategoriesId, product, isLoading, isMobile } = model;
  const translations = useTranslations();

  const [articles, setArticles] = React.useState(_articles);
  const [styles, api] = useSpring(() => ({ opacity: 0, config: { duration: 300 } }));

  React.useEffect(() => {
    if (isLoading || isEqual(_articles, articles)) {
      return;
    }

    // HANDLE INITIAL STATE
    if (_articles.length > 0 && articles.length === 0) {
      setArticles(_articles);
    }

    api.start({
      opacity: 0,
      onResolve: () => {
        setArticles(_articles);
      },
    });
  }, [_articles, isLoading]);

  React.useEffect(() => {
    api.start({ opacity: 1 });
  }, [articles]);

  return (
    <ContentView content={model.childPage}>
      <PageTitle title={translations.articles}>
        <article className="ArticleList">
          <div className="ArticleList__hero">
            <div className="ArticleList__content">
              <Title level={1} className="ArticleList__title">
                {translations.articles}
              </Title>
              <p className="ArticleList__description">{translations.articleListDescription}</p>
            </div>
            <Image.Container aspectRatio="4/3" className="ArticleList__imageWrapper">
              <Image url={heroImageUrl} />
            </Image.Container>
          </div>
          <div className="ArticleList__filters">
            {categoriesSelect && (
              <div className="ArticleList__categoryFilter">
                <Select
                  isMobile={isMobile}
                  options={[
                    { value: allCategoriesId, label: translations.allCategories },
                    ...categoriesSelect.availableItems,
                  ]}
                  onChange={value => categoriesSelect.setValue([value])}
                  value={categoriesSelect.value[0]}
                  label={translations.allCategories}
                />
              </div>
            )}
          </div>
          <animated.div className="ArticleList__listWrapper" style={styles}>
            <List
              items={articles}
              renderItem={article => (
                <SectionArticleItem
                  {...article}
                  title={{ level: 3, size: "big", text: article.title }}
                  category={article.category}
                  goBackText={translations.goToArticleListPage}
                  productParams={product.productParams}
                />
              )}
              empty={
                <ActivityIndicator isLoading={isLoading || _articles.length > 0}>
                  <div>
                    <Image.Container aspectRatio="1/1" className="ArticleList__emptyListImageWrapper">
                      <Image url={emptyListImageUrl} />
                    </Image.Container>
                    <p className="ArticleList__emptyListText">{translations.emptyResultsMessage}</p>
                  </div>
                </ActivityIndicator>
              }
              className="ArticleList__list"
            />
          </animated.div>
        </article>
      </PageTitle>
    </ContentView>
  );
});
