import React from "react";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";

interface ArticleDateProps {
  date: string;
}

export const ArticleDate: React.FC<ArticleDateProps> = props => {
  const { date } = props;
  const formattedDate = new Date(date).toLocaleDateString();
  const translations = useTranslations();

  return (
    <p className="ArticleDate">
      {translations.edited} {formattedDate}
    </p>
  );

};
