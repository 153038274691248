import noop from "lodash/noop";
import { action, computed, observable } from "mobx";
import { BackendApi } from "../../shared/api/BackendApi";
import { ITibetClient } from "../../shared/api/TibetClient";
import { Url } from "../../shared/models/Url";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../shared/services/NavigationService";
import { CourseInfo } from "../../shared/stores/Product";
import { ProductStore } from "../../shared/stores/ProductStore";
import { UserStore } from "../../shared/stores/UserStore";
import { ConfigDto } from "../../types/config/dto/ConfigDto";
import { BasePage } from "../BasePage";
import { ProductParams } from "./addProductRouting";
import { ProductHeroViewModel } from "./productHero/ProductHeroViewModel";
import { NewsletterData } from "./productHero/betaNewsletterPopup/BetaNewsletterPopupViewModel";

export class ProductViewModel extends BasePage {
  public coursesSectionId = "coursesSection";

  @observable pathname;

  @observable algebraModal;

  @action.bound
  toggleAlgebraModal() {
    localStorage.setItem("hasClosedAlgebraModal", "true");
    this.algebraModal = false;
  }

  @computed get showAlgebraModal() {
    if (localStorage.getItem("hasClosedAlgebraModal") === "true") {
      return false;
    }
    if (this.pathname !== "/glu") {
      return false;
    }
    return this.algebraModal;
  }

  @computed get product() {
    if (!this.productStore.activeProduct) {
      throw new Error("Active product have to be specified before creating ProductViewModel");
    }
    return this.productStore.activeProduct;
  }

  @computed get productHeroVM() {
    return new ProductHeroViewModel(
      this.coursesSectionId,
      this.product,
      this.handleCourseClick,
      this.handleNewsletterSubmit
    );
  }

  @computed get productSections() {
    return this.product.sections;
  }

  @computed get courses() {
    return this.product.courses;
  }

  @computed get isBeta() {
    return this.product.isBeta;
  }

  constructor(
    public productParams: ProductParams,
    public config: ConfigDto,
    public productStore: ProductStore,
    public userStore: UserStore,
    public navigation: ILowLevelNavigationService,
    public gtm: IGtmService,
    public tibetClient: ITibetClient,
    public backendApi: BackendApi,
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public handleCourseClick: (slug?: string) => Promise<void>,
    public handleLogin: (slug?: string) => Promise<void>
  ) {
    super(languageService, errorService);
    this.algebraModal = true;
    this.pathname = window.location.pathname;
  }

  protected async loadData() {
    noop();
  }

  handleNewsletterSubmit = async (course: CourseInfo, formData: NewsletterData) => {
    this.backendApi.submitNewsletterSignup({
      courseTitle: course.title,
      newsletter: { email: formData.email, fullName: formData.fullName, profession: formData.profession },
    });
  };

  public async beforeActivated() {
    this.productStore.trySetActiveProductBySlug(this.productParams.productSlug);
    super.beforeActivated();
  }

  @action.bound
  goToCourse(courseSlug: string) {
    this.navigation.redirectTo(Url.toDashboardPage({ ...this.productParams, courseSlug }));
  }
}
