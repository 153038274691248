import noop from "lodash/noop";
import { action, computed, observable } from "mobx";
import { BackendApi } from "../../shared/api/BackendApi";
import { Step, Task } from "../../shared/api/StepService";
import { TaskFactory } from "../../shared/components/tasks/TaskFactory";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../shared/services/NavigationService";
import { bookmarkStoreMock } from "../../shared/stores/mock/BookmarkStore.mock";
import { ProgressStoreMock } from "../../shared/stores/mock/ProgressStore.mock";
import { assertUnreachable } from "../../shared/typeUtils";
import themeMockImageUrl from "../../static/images/dog2.png";
import { ThemeInfoDto } from "../../types/trainingground/dto/ThemeInfoDto";
import { BasePage } from "../BasePage";
import { StepViewModel } from "../lessons/StepStore";
import { StepFactory } from "../lessons/lesson/steps/StepFactory";
import { TrainingTask } from "../trainingGrounds/trainingGround/training/testTask/TrainingTask";
import { PreviewQuery } from "./addStepPreviewRouting";

class GtmServiceMock implements IGtmService {
  pushCookieConsentChanged() {
    noop();
  }

  pushPageViewed() {
    noop();
  }

  pushLoginStarted() {
    noop();
  }

  pushBuyStarted() {
    noop();
  }

  pushVideoEnded() {
    noop();
  }

  pushUserStatusChanged() {
    noop();
  }

  pushSoundCloudEnded() {
    noop();
  }
}

type PreviewMode =
  | { type: "loading" }
  | { type: "lesson"; step: StepViewModel }
  | { type: "training"; task: TrainingTask };
export type PreviewModeType = Pick<PreviewMode, "type">["type"];

export class StepPreviewViewModel extends BasePage {
  @observable private step?: Step;

  @observable private task?: Task;

  @observable mode: PreviewMode = { type: "loading" };

  @computed private get currentLessonStep() {
    if (this.step === undefined) {
      return;
    }

    return this.stepFactory.createStep(this.step);
  }

  @computed private get currentTrainingGroundTask() {
    if (this.task === undefined) {
      return;
    }

    const themeInfo: ThemeInfoDto = {
      id: "Theme-id1",
      title: "Mock theme",
      slug: "Theme-slug",
      image: {
        url: themeMockImageUrl,
        alt: "",
      },
    };

    return new TrainingTask(
      this.activeCourseParamsMock,
      this.taskFactory,
      this.task,
      false,
      themeInfo,
      bookmarkStoreMock
    );
  }

  @computed get isTrainingPreviewAvailable() {
    return this.currentTrainingGroundTask !== undefined;
  }

  get activeCourseParamsMock() {
    return { courseSlug: "123", productSlug: "123" };
  }

  readonly lessonColor = "#d3e1ba";

  readonly stepFactory: StepFactory;

  readonly taskFactory = new TaskFactory();

  constructor(
    public stepSlug: string,
    public previewQuery: Partial<PreviewQuery>,
    public backendApi: BackendApi,
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public navigationService: ILowLevelNavigationService
  ) {
    super(languageService, errorService);
    const progressStoreMock = new ProgressStoreMock();
    const gtmServiceMock = new GtmServiceMock();

    this.stepFactory = new StepFactory(
      this.lessonColor,
      progressStoreMock,
      bookmarkStoreMock,
      backendApi,
      gtmServiceMock,
      this.activeCourseParamsMock,
      this.navigationService
    );
  }

  @action.bound
  changeMode(type: PreviewModeType) {
    if (type === "lesson" && this.currentLessonStep) {
      this.mode = { type: "lesson", step: this.currentLessonStep };
      return;
    }

    if (type === "training" && this.currentTrainingGroundTask) {
      this.mode = { type: "training", task: this.currentTrainingGroundTask };
      return;
    }

    if (type === "loading") {
      this.mode = { type: "loading" };
      return;
    }

    return assertUnreachable();
  }

  private async loadTraining(stepType: Step) {
    switch (stepType._type) {
      case "Task": {
        switch (stepType.task._type) {
          case "ChoiceListTask":
          case "ChoiceTask":
          case "GapTask":
          case "InputTask":
          case "MaskTask":
          case "MatchTask":
            return this.backendApi.getTrainingGroundTaskPreview({
              slug: this.stepSlug,
              isDraft: this.previewQuery.isDraft,
              revision: this.previewQuery.revision,
            });
          case "TextTask":
            return undefined;
          default:
            return assertUnreachable(stepType.task);
        }
      }
      case "Video":
      case "TaskGroup":
      case "TextBlock":
      case "Sound":
        return undefined;
      default:
        return assertUnreachable(stepType);
    }
  }

  protected async loadData() {
    this.step = await this.backendApi.getStepPreview({
      slug: this.stepSlug,
      isDraft: this.previewQuery.isDraft,
      revisionId: this.previewQuery.revision,
    });

    if (this.currentLessonStep) {
      this.mode = { type: "lesson", step: this.currentLessonStep };
    }

    this.task = await this.loadTraining(this.step);
  }
}
