import classNames from "classnames";
import React from "react";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { Link } from "../../link/Link";
import { Level, Title } from "../../title/Title";
import { ThumbnailImage } from "../shared/thumbnailImage/ThumbnailImage";
import { ThumbnailTypes } from "../types/ThumbnailTypes";

type HighlightedThumbnailVariant = "default" | "dark";

export interface HighlightedThumbnailProps {
  title: { text: string; level: Level };
  type: ThumbnailTypes;
  category?: string[];
  description?: string;
  image?: ImageDto;
  overlay?: React.ReactNode;
  locked?: boolean;
  imageBackgroundColor?: string;
  href?: string;
  linkGoBackText?: string;
  variant?: HighlightedThumbnailVariant;
  backgroundColor?: string;
  bottomContent?: React.ReactNode;
}

export const HighlightedThumbnail: React.FC<HighlightedThumbnailProps> = props => {
  const {
    title,
    type,
    category,
    description,
    image,
    overlay,
    locked,
    imageBackgroundColor,
    href,
    linkGoBackText,
    variant = "default",
    backgroundColor,
    bottomContent,
  } = props;

  return (
    <div
      className={classNames(
        "HighlightedThumbnail",
        {
          "HighlightedThumbnail--default": variant === "default",
          "HighlightedThumbnail--dark": variant === "dark",
        },
        { "HighlightedThumbnail--locked": locked }
      )}
      style={{ backgroundColor }}
    >
      <div className="HighlightedThumbnail__imageWrapper" style={{ backgroundColor: imageBackgroundColor }}>
        {overlay}
        <ThumbnailImage type={type} image={image} />
      </div>
      <div className="HighlightedThumbnail__infoBox">
        {category && <span className="HighlightedThumbnail__category">{category}</span>}
        <Title className="HighlightedThumbnail__title" level={title.level}>
          {title.text}
        </Title>
        <p className="HighlightedThumbnail__description">{description}</p>
        <div className="HighlightedThumbnail__bottomContentWrapper">
          <div className="HighlightedThumbnail__bottomContent">{bottomContent}</div>
        </div>
      </div>
      {href && !locked && (
        <Link to={href} type="clear" className="HighlightedThumbnail__linkOverlay" goBackText={linkGoBackText}>
          <span className="visuallyHidden">{title.text}</span>
        </Link>
      )}
    </div>
  );
};
