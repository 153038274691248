import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { PaymentSidePanel } from "../../pages/shared/paymentDialog/PaymentSidePanel";
import { Button } from "../../shared/components/button/Button";
import { CoursesPicker } from "../../shared/components/coursesLoginSelector/CoursesPicker";
import { NavLink } from "../../shared/components/link/NavLink";
import { List } from "../../shared/components/list/List";
import { LogoSection } from "../../shared/components/logoSection/LogoSection";
import { SidePanel } from "../../shared/components/sidePanel/SidePanel";
import { SvgIcon } from "../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import { useIsVisible } from "../../shared/hooks/useIsVisible";
import { CourseColorVersionDto } from "../../types/courses/dto/CourseColorVersionDto";
import { useLocation } from "../../useRouter";
import { HeaderViewModel, SourceLink } from "./HeaderViewModel";
import { HeaderHamburgerView } from "./headerHamburger/HeaderHamburgerView";
import { UserLinksDialog } from "./userLinksDialog/UserLinksDialog";
import { UserLinksSheet } from "./userLinksSheet/UserLinksSheet";

interface HeaderProps {
  model: HeaderViewModel;
  stickyHeaderRef: React.RefObject<HTMLDivElement>;
}

export const HeaderView = observer((props: HeaderProps) => {
  const { model, stickyHeaderRef } = props;
  const {
    logoLink,
    userLinks,
    activeCourse,
    searchLink,
    isAuthenticated,
    baseHeaderLinks,
    userLinksSheet,
    userLinksDialog,
    pickCourseSidePanel,
    availableCoursesCount,
    handleLogin,
    activeProduct,
    paymentSidePanel,
    headerHamburgerModel,
  } = model;
  const translations = useTranslations();
  const isVisible = useIsVisible(stickyHeaderRef);
  const buttonWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const currentPath = location.pathname + location.search;

  const convertToLinkText = (link: SourceLink) => {
    if (link.source === "dynamic") {
      return link.title;
    }

    return translations[link.translation];
  };

  const loginButton = () => {
    if (!activeProduct) {
      return;
    }

    if (isAuthenticated) {
      return;
    }

    if (availableCoursesCount === 0) {
      return;
    }

    if (availableCoursesCount === 1) {
      return (
        <Button
          size="medium"
          type="outline"
          onClick={() => handleLogin(activeProduct.courses[0].slug)}
          className="Header__actionButton"
        >
          {translations.logIn}
        </Button>
      );
    }

    if (activeCourse) {
      return (
        <Button
          size="small"
          type="outline"
          onClick={() => handleLogin(activeCourse.slug)}
          className="Header__actionButton"
        >
          {translations.logIn}
        </Button>
      );
    }

    if (activeCourse === undefined && availableCoursesCount > 1) {
      return (
        <Button onClick={pickCourseSidePanel.show} type="outline" size="small" className="Header__actionButton">
          {translations.logIn}
        </Button>
      );
    }

    return;
  };

  const buyButton = () => {
    if (!activeProduct) {
      return;
    }

    if (activeCourse?.hasFullAccess) {
      return;
    }

    if (availableCoursesCount === 0) {
      return;
    }

    return (
      <Button
        size="small"
        onClick={() => paymentSidePanel?.show()}
        className={classNames("Header__actionButton", "Header__buyButton")}
      >
        {translations.buyAccess}
      </Button>
    );
  };

  return (
    <header
      className={classNames("Header", { "Header--sticky": !isVisible }, { "Header--activeCourse": activeCourse })}
    >
      <div className="Header__inner">
        <div className="Header__content">
          <LogoSection link={logoLink} colorVersion={CourseColorVersionDto.Dark} />
          <List
            className="Header__linkList"
            singleItemClassName="Header__singleLink"
            items={baseHeaderLinks}
            renderItem={link => {
              return (
                <NavLink
                  type="clear"
                  to={link.href}
                  hrefType={link.type}
                  className="Header__link"
                  activeClassName="Header__link--active"
                >
                  <span className="Header__linkText">{convertToLinkText(link)}</span>
                </NavLink>
              );
            }}
          />
          <div className="Header__actionSection">
            {currentPath !== "/" && loginButton()}
            {!isAuthenticated && currentPath === "/" && (
              <Button
                size="medium"
                type="outline"
                onClick={() => handleLogin(currentPath)}
                className="Header__actionButton"
              >
                {translations.logIn}
              </Button>
            )}
            {activeCourse && buyButton()}
            {isAuthenticated && userLinks && (
              <div ref={buttonWrapperRef}>
                <Button type="clear" className="Header__iconWrapperStyling mobileOnly" onClick={userLinksSheet.toggle}>
                  <SvgIcon iconName="user" className="Header__actionIcon" />
                  <span className="visuallyHidden">
                    {translations[userLinksSheet.isOpen ? "hidePersonalLinks" : "showPersonalLinks"]}
                  </span>
                </Button>
                <UserLinksSheet links={userLinks} sheet={userLinksSheet} />
                <Button type="clear" className="Header__iconWrapperStyling tablet" onClick={userLinksDialog.toggle}>
                  <SvgIcon iconName="user" className="Header__actionIcon" />
                  <span className="visuallyHidden">
                    {translations[userLinksDialog.isOpen ? "hidePersonalLinks" : "showPersonalLinks"]}
                  </span>
                </Button>
                <UserLinksDialog
                  popup={userLinksDialog}
                  links={userLinks}
                  referenceElement={buttonWrapperRef.current}
                />
              </div>
            )}
            {searchLink && (
              <NavLink
                to={searchLink.href}
                type="clear"
                className="Header__iconWrapperStyling Header__tabletActionItem"
                activeClassName="Header__iconWrapperStyling--active"
              >
                <SvgIcon iconName="search" className="Header__actionIcon" />
                <span className="visuallyHidden">{translations[searchLink.translation]}</span>
              </NavLink>
            )}
            <HeaderHamburgerView model={headerHamburgerModel} />
          </div>
          <SidePanel isOpen={pickCourseSidePanel.isOpen} onClose={pickCourseSidePanel.close}>
            <CoursesPicker activeProduct={activeProduct} handleLogin={handleLogin} />
          </SidePanel>
          {paymentSidePanel && <PaymentSidePanel modal={paymentSidePanel} />}
        </div>
      </div>
    </header>
  );
});
