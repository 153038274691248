import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { ReactSVG } from "react-svg";
import { Accordion } from "../../shared/components/accordion/AccordionView";
import { HrefType, Link } from "../../shared/components/link/Link";
import { List } from "../../shared/components/list/List";
import { LogoSection } from "../../shared/components/logoSection/LogoSection";
import { ProductCopyright } from "../../shared/components/productCopyright/ProductCopyright";
import { Title } from "../../shared/components/title/Title";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import gyldendalLogoUrl from "../../static/images/iconsAnimated/logo-desktop.svg";
import { CourseColorVersionDto } from "../../types/courses/dto/CourseColorVersionDto";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { SourceLink } from "../header/HeaderViewModel";
import { FooterViewModel } from "./FooterViewModel";

interface FooterProps {
  model: FooterViewModel;
}

export const FooterView: React.FC<FooterProps> = observer((props: FooterProps) => {
  const { model } = props;
  const translations = useTranslations();
  const convertLinkBySource = (link: SourceLink) =>
    link.source === "static" ? translations[link.translation] : link.title;

  return (
    <footer className="Footer">
      <div className="Footer__wrapper">
        <div className="Footer__logo">
          <LogoSection
            colorVersion={CourseColorVersionDto.Light}
            studiesTypeLink={model.studiesTypeLink}
            link={model.logoLink}
          />
        </div>
        <div
          className={classNames("Footer__middleSection", {
            "Footer__middleSection--withFeedbackButton": Boolean(model.feedbackButtonLink),
          })}
        >
          <List
            items={model.linkSections}
            className="Footer__linksSectionsList"
            singleItemClassName="Footer__singleLinksSection"
            renderItem={section => {
              return (
                <>
                  <div className="Footer__sectionTitleWrapper">
                    <div className="Footer__sectionTitle">{translations[section.translation]}</div>
                  </div>
                  <div className="Footer__sectionCollapseWrapper">
                    <Accordion
                      model={section.accordion}
                      content={
                        <List
                          items={section.links}
                          singleItemClassName="Footer__singleSectionLinkWrapper"
                          renderItem={link => (
                            <Link to={link.href} type="clear" className="Footer__singleSectionLink">
                              {convertLinkBySource(link)}
                            </Link>
                          )}
                        />
                      }
                      title={translations[section.translation]}
                      variant="light"
                    />
                  </div>
                  <List
                    items={section.links}
                    singleItemClassName="Footer__singleSectionLinkWrapper Footer__singleSectionDesktopLinkWrapper"
                    className="Footer__sectionLinksWrapper"
                    renderItem={link => (
                      <Link to={link.href} type="clear" className="Footer__singleSectionLink">
                        {convertLinkBySource(link)}
                      </Link>
                    )}
                  />
                </>
              );
            }}
          />
          {model.feedbackButtonLink && (
            <>
              <div className="Footer__middleSectionSplitLine" />
              <div className="Footer__feedbackSection">
                <Title level={2} className="Footer__feedbackTitle">
                  {translations.helpUsImprove}
                </Title>
                <p className="Footer__feedbackDescription">{translations.whatDoYouThinkAboutThisService}</p>
                <Link
                  to={model.feedbackButtonLink}
                  type="button"
                  buttonType="outline"
                  buttonVariant="tertiary"
                  className="Footer__feedbackButton"
                >
                  {translations.sayYourOpinion}
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="Footer__bottomSection">
          {model.shouldShowGyldendalLogo && (
            <div className="Footer__gyldendalWrapper">
              <span>{translations.weArePartOf}</span>
              <Link
                to={RouteConfiguration.GyldendalPage}
                className="ErrorFooter__logoLink"
                hrefType={HrefType.External}
                aria-label={translations.goToGyldendalSite}
              >
                <ReactSVG src={gyldendalLogoUrl} className="Footer__gyldendalLogo" />
              </Link>
            </div>
          )}
          <div className="Footer__copyrightSection">
            <span className="Footer__copyrightText">
              <ProductCopyright />
            </span>
            {model.termsAndConditionsPageLink && (
              <>
                <div className="Footer__bottomSplitLine" />
                <Link to={model.termsAndConditionsPageLink.href} type="clear" className="Footer__privacyPolicyArticle">
                  {translations[model.termsAndConditionsPageLink.translation]}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
});
