import { observer } from "mobx-react";
import * as React from "react";
import { ContentView } from "react-mvvm";
import { ActivityIndicator } from "../../shared/components/activityIndicator/ActivityIndicator";
import { PaymentSidePanel } from "../shared/paymentDialog/PaymentSidePanel";
import { CourseViewModel } from "./CourseViewModel";

export const CourseView = observer(({ model }: { model: CourseViewModel }) => {
  return (
    <div className="CourseView">
      <ActivityIndicator className="CourseView__loader" isLoading={model.isLoading}>
        <ContentView content={model.childPage} />
        {model.paymentSidePanelViewModel && <PaymentSidePanel modal={model.paymentSidePanelViewModel} />}
      </ActivityIndicator>
    </div>
  );
});
