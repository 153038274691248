import classNames from "classnames";
import React from "react";
import { Radio } from "../../../../shared/components/radio/Radio";
import { useRadioGroupContext } from "../../../../shared/components/radio/RadioGroupContext";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { FormattedText } from "../../../../shared/textUtils";
import { PriceOptionDto } from "../../../../types/courses/dto/PriceOptionDto";

interface BuyCourseItemProps {
  option: PriceOptionDto;
}

export const BuyCourseItem: React.FC<BuyCourseItemProps> = props => {
  const {
    option: { productPriceId, displayName, nokPrice, regularNokPrice },
  } = props;
  const translations = useTranslations();
  const { value, onChange, disabled } = useRadioGroupContext();
  const isOptionSelected = productPriceId === value;
  const isPromotional = !!regularNokPrice;
  const promotionValue = isPromotional ? Math.round((regularNokPrice - nokPrice) / (regularNokPrice / 100)) : undefined;

  const onContainerClick = () => {
    if (disabled) {
      return;
    }

    onChange(productPriceId);
  };

  return (
    <div
      className={classNames("BuyCourseItem", {
        "BuyCourseItem--active": isOptionSelected,
        "BuyCourseItem--promotional": isPromotional,
      })}
      role="presentation"
      onClick={onContainerClick}
    >
      <div className="BuyCourseItem__info">
        <div className="BuyCourseItem__radioWrapper">
          <Radio id={productPriceId}>{displayName}</Radio>
        </div>
        <p className={classNames("BuyCourseItem__price", { "BuyCourseItem__price--promotional": isPromotional })}>
          {FormattedText(translations.priceValue, [nokPrice])}
        </p>
        {isPromotional && (
          <span className="BuyCourseItem__regularPrice">
            {FormattedText(translations.regularPriceValue, [regularNokPrice])}
          </span>
        )}
      </div>
      {!!promotionValue && (
        <div className="BuyCourseItem__promotionValue">
          {FormattedText(translations.promotionValue, [promotionValue])}
        </div>
      )}
    </div>
  );
};
