import * as React from "react";
import { SanityImage } from "../../../../shared/components/image/Image";
import { Link } from "../../../../shared/components/link/Link";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams, ContentReference } from "../../../../shared/models/Url";
import { FormattedText } from "../../../../shared/textUtils";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { useUrl } from "../../../../useRouter";

export interface ThemeArticleItemProps {
  contentRef: ContentReference;
  title: string;
  activeCourseParams: ActiveCourseParams;
  image?: ImageDto;
  description?: string;
  categories?: string[];
  color?: string;
  themeTitle: string;
}

export const ThemeArticleItem: React.FC<ThemeArticleItemProps> = ({
  contentRef,
  title,
  activeCourseParams,
  image,
  description,
  categories,
  color,
  themeTitle,
}) => {
  const translations = useTranslations();
  const url = useUrl();

  return (
    <div className="ThemeArticleItem">
      <div className="ThemeArticleItem__imageContainer" style={{ backgroundColor: color }}>
        {image && <SanityImage {...image} className="ThemeArticleItem__image" />}
      </div>
      <div className="ThemeArticleItem__info">
        <span className="ThemeArticleItem__category">{categories || translations.article}</span>
        <h3 className="ThemeArticleItem__title">{title}</h3>
        <p className="ThemeArticleItem__description">{description}</p>
      </div>
      <Link
        type="clear"
        to={url.toCourseArticlePage(activeCourseParams, contentRef)}
        className="ThemeArticleItem__linkOverlay"
        goBackText={FormattedText(translations.goToX, [themeTitle])}
      >
        <span className="visuallyHidden">{title}</span>
      </Link>
    </div>
  );
};
