import { observer } from "mobx-react";
import * as React from "react";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { CourseButton } from "../CourseButton/CourseButton";
import { Image, SanityImage } from "../image/Image";

export interface CourseThumbnailProps {
  title: string;
  image: ImageDto;
  productTitle: string;
  slug: string;
  onClick: (slug: string) => void;
}

export const CourseThumbnail = observer((props: CourseThumbnailProps) => {
  const { title, image, productTitle, onClick, slug } = props;

  return (
    <div className="CourseThumbnail">
      <div className="CourseThumbnail__imageWrapper">
        <Image.Container aspectRatio="16/9">
          <SanityImage {...image} />
        </Image.Container>
      </div>
      <div className="CourseThumbnail__content">
        <p className="CourseThumbnail__productTitle">{productTitle}</p>
        <h3 className="CourseThumbnail__courseTitle">{title}</h3>
        <CourseButton courseSlug={slug} onClick={onClick} />
      </div>
    </div>
  );
});
